import FleetForm from "./FleetForm";
import {Container, Header, Segment} from "semantic-ui-react";
import {useParams} from "react-router-dom";
import axiosInstance from "../../utils/axiosInstance";
import {useEffect, useState} from "react";

function FleetUpdate() {

    const [fleet, setFleet] = useState(null);
    const {id} = useParams();
    useEffect(() => {
        axiosInstance.get("/fleets/" + id)
            .then((response) => {
                setFleet(response.data);
            })
            .catch((error) => {
                console.log(error)
            });
    }, []);

    return <Container>
        <Header block as='h4' attached='top' style={{marginTop: '5px'}}>
            Modification de la flotte
        </Header>
        <Segment attached>
            <FleetForm fleet={fleet}/>
        </Segment>
    </Container>


}

export default FleetUpdate