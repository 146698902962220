import React from "react";
import {Icon, Popup} from "semantic-ui-react";
import {toastCopySuccess} from "../../reducers/Global/global.actions";
import {useTranslation} from "react-i18next";

function CopyToClipboard({text}) {
    const {t} = useTranslation();

    const onClick = () => {
        navigator.clipboard.writeText(text)
        toastCopySuccess(t('generic.copy-done'));
    };

    return (
        <span style={{cursor: "pointer"}} onClick={onClick}>
            {text}<Popup content={t('generic.copy')} size={"mini"} position='right center' trigger={<Icon style={{marginLeft: '5px'}} name={"copy outline"}/>} />
        </span>
    );
}


export default CopyToClipboard;
