import {Form, Grid, Icon, Input, Message, Popup} from 'semantic-ui-react'
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isIMEI} from "../../utils/imei-utils";
import {updateProduct} from "../../reducers/Create/create.actions";
import axiosInstance from "../../utils/axiosInstance";
import PageHeader from "../PageHeader/PageHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {isDevEnv, isPreprodEnv} from "../../utils/authorities-utils";
import {useTranslation} from "react-i18next";

function SelectModelComponent(props) {

    const {t} = useTranslation();

    const dispatch = useDispatch();
    const {setStep, setPreviousButtonState, setNextButtonState} = props;
    const createIntervention = useSelector(state => state.create);
    const [imei, setImei] = useState('');
    const [isValid, setIsValid] = useState(false);

    const handleInputChange = (event) => {
        let inputValue = event.target.value.replace(/\D/g, '').slice(0, 15);
        if((isDevEnv() || isPreprodEnv()) && inputValue === '123') {
            inputValue = '355824088620921';
        }
        setImei(inputValue);
        if (createIntervention.product && createIntervention.product.imei) {
            dispatch(updateProduct(null));
        }
        if (isIMEI(inputValue)) {
            setIsValid(true)
            axiosInstance.get("/imei/" + inputValue).then(response => {
                if (response.data) {
                    dispatch(updateProduct({
                        imei: inputValue,
                        imgURL: response.data.mobileModelURL,
                        brand: {
                            id: response.data.brand.id,
                            name: response.data.brand.name
                        },
                        model: {
                            id: response.data.id,
                            name: response.data.name,
                        }
                    }));
                    setIsValid(true)
                    setStep("SELECT_COLOR");
                } else {
                    setIsValid(false);
                }
            });
        } else {
            setIsValid(false);
        }
    };

    useEffect(() => {
        // TODO : fix focus on input (bug d'affichage)
        // document.getElementById('imei').focus();
        setPreviousButtonState({
            disabled: false,
            onPrevious: () => {setStep("SELECT_PRODUCT_TYPE")}
        });
    }, [])

    useEffect(() => {
        if (createIntervention.product && isIMEI(createIntervention.product.imei)) {
            setIsValid(true);
        }
        setNextButtonState({
            disabled: !createIntervention.product || !createIntervention.product.imei,
            label: t('generic.next'),
            isPrimary: true,
            onNext: () => handleInputChange({target: {value: createIntervention.product.imei}})
        });
    }, [imei])

    function selectImeiDev() {

    }

    return (
        <>
            <PageHeader title={t('page.intervention.product')} onDoubleClick={() => selectImeiDev()}/>

            <Popup trigger={<label>{t('page.intervention.product-imei')}</label>} />
            <Grid>
                <Grid.Row>
                    <Grid.Column width={10}>
                        <Form>
                            <Form.Field>
                                <Input icon={<Icon name={isValid ? 'check' : imei.length === 0 ? '' : 'times'}
                                                   color={isValid ? 'teal' : imei.length === 0 ? 'teal' : 'red'}/>}
                                       id={'imei'}
                                       placeholder="IMEI"
                                       defaultValue={createIntervention.product ? createIntervention.product.imei : null}
                                       onChange={handleInputChange}
                                       maxLength={15}
                                       size={"massive"}
                                       style={{
                                           fontSize: '50px',
                                           border: '1px solid #00BFB2',
                                           lineHeight: '90px',
                                           display: 'inline-block',
                                           height: '100%'
                                       }}
                                />
                            </Form.Field>
                        </Form>
                    </Grid.Column>
                    <Grid.Column width={6}>
                        <Message className="imei-message-box">
                            <Message.Header>{t('page.intervention.how-to')}</Message.Header>
                            <Message.List>
                                <Message.Item>{t('page.intervention.how-to-1')}<b>*#06#</b> {t('page.intervention.how-to-2')}</Message.Item>
                                <Message.Item>{t('page.intervention.how-to-3')}</Message.Item>
                                <Message.Item>{t('page.intervention.how-to-4')}</Message.Item>
                            </Message.List>
                        </Message>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{paddingTop: '0px'}}>
                    <Grid.Column width={10} >
                        <p className="imei-input-info-text"><FontAwesomeIcon icon="fa-regular fa-circle-info" />&nbsp;&nbsp;
                            {t('page.intervention.imei-help')}
                        </p>
                        <p style={{fontSize: '16px'}}>
                            {t('page.intervention.unknown-imei')} <a onClick={() => setStep("MODEL_SELECTOR")}>
                            {t('page.intervention.chose-by-brand')}</a>
                        </p>
                    </Grid.Column>
                    <Grid.Column width={6}>
                        </Grid.Column>
                </Grid.Row>
            </Grid>




        </>
    );
}


export default SelectModelComponent;
