import {ADD_TO_LOGGING, RESET_LOADING_MESSAGE, RESET_LOGGING_LIST, SET_LOADING_MESSAGE} from "./global.types";

const INITIAL_STATE = {
    dimmerMessage: "",
    logging: []
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case SET_LOADING_MESSAGE: {
            return {
                ...state,
                dimmerMessage: action.dimmerMessage,
            }
        }

        case RESET_LOADING_MESSAGE: {
            return {
                ...state,
                dimmerMessage: "",
            }
        }

        case ADD_TO_LOGGING: {
            return {
                ...state,
                logging: [{...action.object, date: action.date}, ...state.logging],
            }
        }

        case RESET_LOGGING_LIST: {
            return {
                ...state,
                logging: [],
            }
        }

        default:
            return state;
    }
};

export default reducer;