import {createContext, useContext, useEffect, useState} from "react";
import axiosInstance from "../utils/axiosInstance";

const BrandCacheContext = createContext({});

const useBrandCache = () => useContext(BrandCacheContext);

const useBrandList = (search) => {
    const cache = useBrandCache();
    const [data, setData] = useState([]);
    const [options, setOptions] = useState([]);


    const generateOptions = (types) => {
        let array = [{key: 0, text: "", value: {id: 0, name: ""}}];
        for (let type of types) {
            array.push({
                key: type.id, text: type.name, value: {
                    id: type.id,
                    name: type.name
                }
            })
        }
        setOptions(array)
    }

    useEffect(() => {

        if (cache.brandsData) {
            setData(cache.brandsData);
            generateOptions(cache.brandsData);
        } else {
            axiosInstance.get("/brands").then(response => {
                cache.brandsData = response.data;
                setData(response.data);
                generateOptions(response.data);
            });
        }
    }, []);

    return [data, options];
};

export const BrandCacheProvider = ({children}) => {
    const cache = {
        brandsData: null
    };

    return (
        <BrandCacheContext.Provider value={cache}>
            {children}
        </BrandCacheContext.Provider>
    );
};

export default useBrandList;
