import React, {useEffect, useState} from "react";
import {Button, Container, Form, Icon, Label, Menu, Modal, Segment} from "semantic-ui-react";
import {useSelector} from "react-redux";
import useAjax from "../hooks/useAjax";
import axiosInstance from "../utils/axiosInstance";
import {useForm} from "react-hook-form";
import {toastSuccess} from "../reducers/Global/global.actions";
import {useTranslation} from "react-i18next";

const AccountManagementPage = () => {

        const user = useSelector(state => state.user);
        const {t} = useTranslation();

        const [userDetails] = useAjax("/user", "get", null);
        const [newPassword, setNewPassword] = useState("");
        const [showModalPassword, setShowModalPassword] = useState(false);
        const [userEdition, setUserEdition] = useState(false);
        const {register, handleSubmit, setValue, getValues, formState: {errors, isValid, isDirty}} = useForm();

        function updatePassword() {
            axiosInstance.post("/user/password/update", {
                newPassword: newPassword
            })
                .then((response) => {
                    setShowModalPassword(false);
                    toastSuccess(t('page.account.success-message'));
                })
        }

        const onSubmit = (data) => {
            updateUser();
        }

        useEffect(() => {
            if (userDetails) {
                setValue("login", userDetails.login)
                setValue("email", userDetails.email)
                setValue("firstname", userDetails.firstname)
                setValue("lastname", userDetails.lastname)
            }
        }, [userDetails])

        function updateUser() {

            axiosInstance.post("/user/update", {
                email: getValues("email"),
                firstname: getValues("firstname"),
                lastname: getValues("lastname")
            })
                .then((response) => {
                    setUserEdition(false);
                    toastSuccess(t('page.account.success-data'));
                })

        }

        function resetUserForm() {
            setUserEdition(false);
            setValue("login", userDetails.login)
            setValue("email", userDetails.email)
            setValue("firstname", userDetails.firstname)
            setValue("lastname", userDetails.lastname)
        }

    function validateNewPassword(newPassword) {
        const strongPasswordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{12,})");
        return !strongPasswordRegex.test(newPassword);
    }

    return (
            <Container>

                <Modal open={showModalPassword} onClose={() => setShowModalPassword(false)}>
                    <Modal.Header>{t('page.account.change-password')}</Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <Form>
                                <Form.Input type='password' label={t('page.account.new-password')} placeholder={t('page.account.new-password')}
                                            onChange={(e) => setNewPassword(e.target.value)}/>
                            </Form>
                            {<Label basic style={{marginTop: '8px'}}>
                                {t('page.account.password-rules')}
                            </Label>}
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => setShowModalPassword(false)}>
                            {t('generic.cancel')}
                        </Button>
                        <Button
                            primary
                            disabled={validateNewPassword(newPassword)}
                            content={t('generic.validate')}
                            labelPosition='right'
                            icon='checkmark'
                            onClick={() => updatePassword()}
                        />
                    </Modal.Actions>
                </Modal>

                <h2>{t('page.account.subtitle')}</h2>
                <Segment attached>
                    <Form>
                        <Form.Group widths='equal'>
                            <Form.Field required={true}>
                                <label htmlFor="login">{t('generic.login')}</label>
                                <input name="login" id={"lastName"} readOnly={!userEdition} disabled={true}
                                       {...register('login', {required: true})}
                                       placeholder={t('generic.login')}/>
                            </Form.Field>
                            <Form.Field required={true}>
                                <label htmlFor="email">{t('generic.email')}</label>
                                <input name="email" id={"email"} readOnly={!userEdition}
                                       {...register('email', {
                                           required: true,
                                           pattern: {
                                               value: /\S+@\S+\.\S+/,
                                               message: t('page.account.email-notvalid')
                                           }
                                       })}
                                       placeholder={t('generic.email')}/>
                                {errors.email && <span style={{color: 'red'}}>{errors.email.message}</span>}
                            </Form.Field>
                        </Form.Group>

                        <Form.Group widths='equal'>
                            <Form.Field required={true}>
                                <label htmlFor="firstname">{t('generic.firstname')}</label>
                                <input name="firstname" id={"firstname"} readOnly={!userEdition}
                                       {...register('firstname', {required: true})}
                                       placeholder={t('generic.firstname')}/>
                            </Form.Field>
                            <Form.Field required={true}>
                                <label htmlFor="lastname">{t('generic.lastname')}</label>
                                <input name="lastname" id={"lastname"} readOnly={!userEdition}
                                       {...register('lastname', {required: true})}
                                       placeholder={t('generic.lastname')}/>
                            </Form.Field>
                        </Form.Group>


                    </Form>

                    {userDetails && userDetails.roles &&
                        userDetails.roles.split(';').map((value, index) =>
                            <Label color={"blue"} key={index}>{t("roles." + value)}</Label>)
                    }
                </Segment>

                <Menu borderless={true} text>
                    <Menu.Menu position='right'>
                        {!userEdition && <Menu.Item>
                            <Button
                                floated='right'
                                labelPosition='left'
                                icon
                                primary
                                size='small'
                                onClick={() => setShowModalPassword(true)}>
                                <Icon name='lock'/> {t('page.account.change-password')}
                            </Button>
                        </Menu.Item>}
                        {!userEdition && <Menu.Item>
                            <Button
                                floated='right'
                                labelPosition='left'
                                icon
                                primary
                                size='small'
                                onClick={() => setUserEdition(true)}>
                                <Icon name='edit'/> {t('page.account.activate-modification')}
                            </Button>
                        </Menu.Item>}
                        {userEdition && <Menu.Item>
                            <Button
                                floated='right'
                                labelPosition='left'
                                icon
                                size='small'
                                onClick={() => resetUserForm()}>
                                <Icon name='cancel'/> {t('generic.cancel')}
                            </Button>
                        </Menu.Item>}
                        {userEdition && <Menu.Item>
                            <Button
                                floated='right'
                                labelPosition='left'
                                icon
                                positive
                                size='small'
                                disabled={!isValid}
                                onClick={handleSubmit(onSubmit)}>
                                <Icon name='check'/> {t('generic.validate')}
                            </Button>
                        </Menu.Item>}
                    </Menu.Menu>
                </Menu>

            </Container>
        );
    }
;

export default AccountManagementPage;
