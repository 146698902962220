import moment from "moment";
import i18n from "i18next";

export function rangeDateOfLastXDays(daysAmount) {

    let rangeDate = [];
    let fromDate = moment().subtract(daysAmount, 'days');
    let toDate = moment();
    rangeDate.push(fromDate.startOf('day').toDate());
    rangeDate.push(toDate.endOf('day').toDate());
    return rangeDate;
}

export function rangeDateOfFullMonth(monthOffsetFromNow) {
    let rangeDate = [];
    let fromDate = moment().add(monthOffsetFromNow, 'months');
    let toDate = moment().add(monthOffsetFromNow, 'months');
    fromDate.startOf('month');
    toDate.endOf('month');
    rangeDate.push(fromDate.startOf('day').toDate());
    rangeDate.push(toDate.endOf('day').toDate());
    return rangeDate;
}

export function rangeDateOfFullYear(yearOffsetFromNow) {
    let rangeDate = [];
    let fromDate = moment().add(yearOffsetFromNow, 'years');
    let toDate = moment().add(yearOffsetFromNow, 'years');
    fromDate.startOf('years');
    toDate.endOf('years');
    rangeDate.push(fromDate.startOf('day').toDate());
    rangeDate.push(toDate.endOf('day').toDate());
    return rangeDate;
}

export function transformRangeDate(rangeDate) {
    let fromDate = moment(rangeDate[0]);
    let toDate = moment(rangeDate[1]);
    return [fromDate.startOf('day').toDate(),toDate.endOf('day').toDate()];
}

export function getMonthLabel(monthsOffsetFromNow) {
    moment.locale(i18n.language);
    let month = moment().add(monthsOffsetFromNow, 'months');
    return month.format("MMMM YYYY");
}

export function getYearLabel(yearsOffsetFromNow) {
    moment.locale(i18n.language);
    let month = moment().add(yearsOffsetFromNow, 'years');
    return month.format("YYYY");
}
