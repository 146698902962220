import {Card, CardGroup, Container, Form, Grid, Image, Menu} from 'semantic-ui-react'
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import useBrandList from "../../hooks/useBrandList";
import {updateProduct} from "../../reducers/Create/create.actions";
import PageHeader from "../PageHeader/PageHeader";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import useAjax from "../../hooks/useAjax";

function SelectModelByBrandModelComponent(props) {

    const {t} = useTranslation();

    const [activeSearch, setActiveSearch] = useState(false);
    const dispatch = useDispatch();
    const [brandId, setBrandId] = useState(null);
    const {setStep, setPreviousButtonState, setNextButtonState} = props;
    const createIntervention = useSelector(state => state.create);
    const [brand, setBrand] = useState(null);
    const [model, setModel] = useState(null);
    const [serial, setSerial] = useState(null);
    const [activeMenu, setActiveMenu] = useState("brand");
    const [, brandsOptions] = useBrandList(activeSearch);


    const [options, setOptions] = useState([]);
    const [newModels] = useAjax(brandId ? "/model/"+createIntervention.productType+"/brand/" + brandId : null, "get", null);

    useEffect(() => {
        let array = [{key: 0, text: "", value: ""}];
        for (let type of newModels) {
            array.push({
                key: type.name, text: type.name, value: {
                    id: type.id,
                    name: type.name
                }
            })
        }
        setOptions(array)
    }, [brandId, newModels]);


    const productTypeIcon = new Map([
        ["SMA", "fa-regular fa-mobile"],
        ["TAB", "fa-regular fa-tablet"],
        ["CSL", "fa-regular fa-gamepad-modern"],
        ["LPT", "fa-regular fa-laptop"],
        ["COM", "fa-regular fa-laptop"]]
    );

    const initialSmartphoneBrands = [
        {id: 36, name: 'Apple', img: "https://asset-fr.trepidai.pro/brand/save/logo-apple.png"},
        {id: 14, name: 'Samsung', img: "https://asset-fr.trepidai.pro/brand/save/logo-samsung.png"},
        {id: 1563, name: 'Honor', img: "https://asset-fr.trepidai.pro/brand/save/logo-honor.png"},
        {id: 37, name: 'Huawei', img: "https://asset-fr.trepidai.pro/brand/save/logo-huawei.png"}];

    const initialConsoleBrands = [
        {id: 60, name: 'Sony', img: "https://asset-fr.trepidai.pro/brand/save/logo-sony.png"},
        {id: 2697, name: 'Nintendo', img: "https://asset-fr.trepidai.pro/brand/save/logo-nintendo.png"},
        {id: 84, name: 'Microsoft', img: "https://asset-fr.trepidai.pro/brand/save/logo-microsoft.png"}];

    const initialComputerBrands = [
        {id: 236, name: 'Acer', img: "https://asset-fr.trepidai.pro/brand/save/logo-acer.png"},
        {id: 36, name: 'Apple', img: "https://asset-fr.trepidai.pro/brand/save/logo-apple.png"},
        {id: 29, name: 'Asus', img: "https://asset-fr.trepidai.pro/brand/save/logo-asus.png"},
        {id: 52, name: 'Dell', img: "https://asset-fr.trepidai.pro/brand/save/logo-dell.png"},
        {id: 334, name: 'HP', img: "https://asset-fr.trepidai.pro/brand/save/logo-hp.png"},
        {id: 37, name: 'Huawei', img: "https://asset-fr.trepidai.pro/brand/save/logo-huawei.png"}];

    let initialBrands;
    if(createIntervention.productType === "CSL") {
        initialBrands = initialConsoleBrands;
    } else if(createIntervention.productType === "LPT") {
        initialBrands = initialComputerBrands;
    } else {
        initialBrands = initialSmartphoneBrands;
    }

    useEffect(() => {
        setPreviousButtonState({
            disabled: false,
            onPrevious: () => {
                setStep("SELECT_PRODUCT_TYPE")
            }
        });
        setNextButtonState({
            disabled: !model,
            label: t('generic.next'),
            isPrimary: true,
            onNext: () => validStep()
        });

    }, [brand, model, serial]);

    function validStep() {
        dispatch(updateProduct({serial: serial, brand: brand, model: model}));
        setStep("SELECT_COLOR");
    }

    function selectBrand(brand) {
        setBrandId(brand.id);
        setBrand(brand);
        setActiveMenu("model");
    }

    function selectModel(model) {
        setModel(model);
    }

    return (
        <>
            <PageHeader title={t('page.intervention.product')}/>

            <div>
                <Menu pointing secondary>
                    <Menu.Item
                        name={t('generic.brand')}
                        active={activeMenu === 'brand'}
                        onClick={() => setActiveMenu("brand")}
                    />
                    <Menu.Item
                        name={t('generic.model')}
                        disabled={!brand}
                        active={activeMenu === 'model'}
                        onClick={() => setActiveMenu("model")}
                    />
                </Menu>

                {activeMenu === "brand" && <>
                    <h5 style={{textAlign: "center"}}>{t('page.intervention.select-brand')}</h5>
                    <CardGroup centered={true}>
                        {initialBrands.map((brand) =>
                            <Card style={{maxWidth: "100px"}} onClick={() => selectBrand(brand)} key={brand.id}>
                                <Image src={brand.img} size={"mini"} ui={false}
                                       style={{maxWidth: "100px", maxHeight: "100px", minWidth: "100px", minHeight: "100px", background: "white"}}></Image>
                                <Card.Content style={{background: "#e8e8e8", fontWeight: "900"}}>
                                    <Card.Description textAlign={"center"}>{brand.name}</Card.Description>
                                </Card.Content>
                            </Card>
                        )}
                    </CardGroup>
                    <br/>
                    <h4 style={{textAlign: "center", color: '#00BFB2'}}>{t('generic.or')}</h4>

                    <h5 style={{textAlign: "center"}}>{t('page.intervention.select-all-brand')}</h5>

                    <Container style={{textAlign: "center", width:"250px"}}>
                    <Form>
                        <Form.Select
                            name="brandId"
                            placeholder={t('page.intervention.select-brand')}
                            options={brandsOptions}
                            value={brand}
                            search
                            onSearchChange={() => setActiveSearch(true)}
                            clearable
                            onChange={async (e, {name, value}) => {
                                if(value && value.id && value.id != 0) {
                                    selectBrand(value)
                                }
                            }}
                        />
                    </Form>
                    </Container>
                </>
                }

                {activeMenu === "model" && <>
                    <div style={{width:"300px"}}>
                        <Form>
                            <Grid className={"selected-product-type-component selected"} columns={"2"}>
                                <Grid.Column verticalAlign={"middle"} width={"5"}>
                                    <div>
                                        <FontAwesomeIcon icon={productTypeIcon.get(createIntervention.productType)}
                                                         size={"2x"}/>
                                    </div>
                                </Grid.Column>
                                <Grid.Column verticalAlign={"middle"}>
                                    <div>
                                        {t('product.type.' + createIntervention.productType)} {brand.name}<br/>
                                    </div>
                                </Grid.Column>
                            </Grid>
                            <br/>
                            <Form.Select
                                name="brandId"
                                label={t('generic.model')}
                                placeholder={t('page.intervention.select-model')}
                                options={options}
                                search
                                disabled={!brandId}
                                onSearchChange={() => setActiveSearch(true)}
                                fluid
                                allowAdditions
                                clearable
                                value={model}
                                additionLabel="Ajouter  "
                                onChange={async (e, {name, value}) => {
                                    if (!options.some(option => option.value === value)) {
                                        const newOption = {key: value, text: value, value: {id: value, name: value}};
                                        setOptions(prevOptions => [...prevOptions, newOption]);
                                        setModel(newOption.value)
                                    } else {
                                        setModel(value);
                                    }
                                }}
                            />
                            <p className="imei-input-info-text">
                                <FontAwesomeIcon icon="fa-regular fa-circle-info"/>
                                &nbsp;&nbsp;
                                {t('page.intervention.absent-model')}
                            </p>
                            <Form>
                                <Form.Input
                                    label={t('generic.serial-number')}
                                    required={false}
                                    name="serial"
                                    placeholder={t('generic.serial-number')}
                                    onChange={async (e, {name, value}) => {
                                        setSerial(value)
                                    }
                                    }/>
                            </Form>
                        </Form>
                    </div>
                </>}

            </div>
        </>
    );
}

export default SelectModelByBrandModelComponent;
