import {Button, Checkbox, Container, Form, Header, Icon, Message, Modal, Progress, Segment} from 'semantic-ui-react'
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {clearCreationWorkflow, updateConditions, updateInterventionId} from "../../reducers/Create/create.actions";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import axiosInstance from "../../utils/axiosInstance";
import PageHeader from "../PageHeader/PageHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SelectShoptable from "./SelectShopTable";
import {toastSuccess} from "../../reducers/Global/global.actions";
import i18n, {t} from "i18next";
import {useTranslation} from "react-i18next";

function FinalValidationComponent(props) {

    const {t} = useTranslation();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {setStep, setPreviousButtonState, setNextButtonState} = props;
    const createIntervention = useSelector(state => state.create);
    const broker = useSelector(state => state.user.broker);
    const [isLoading, setIsLoading] = React.useState(false);
    const [interventionId, setInterventionId] = React.useState(null);
    const [selectShopModal, setSelectShopModal] = useState(false);
    const {
        register,
        handleSubmit,
        getValues,
        setValue,
        setError,
        clearErrors,
        watch,
        formState: {errors, isValid, isDirty}
    } = useForm();
    const [shopId, setShopId] = useState(null);
    const [proximity, setProximity] = useState(true);
    const [shopCount, setShopCount] = useState(0);
    const [createdIntervention, setCreatedIntervention] = useState(null);
    const [inputValue, setInputValue] = useState("");


    const franchiseValue = watch('franchise');
    const thresholdAmountTTCValue = watch('thresholdAmountTTC');

    const onSubmit = () => {
        clearErrors();
        dispatch(updateConditions(getValues()));
        setIsLoading(true)
        let franchise = getValues("franchise") ? parseFloat(getValues("franchise").replace(/,/, '.')) : "";
        let thresholdAmountTTC = getValues("thresholdAmountTTC") ? parseFloat(getValues("thresholdAmountTTC").replace(/,/, '.')) : "";

        if (createIntervention.guaranteeType !== 'INSURANCE') {
            axiosInstance.post("/intervention", {
                brandName: createIntervention.product.brand.name,
                modelName: createIntervention.product.model.name,
                imei: createIntervention.product.imei ? createIntervention.product.imei : "",
                serial: createIntervention.product.serial,
                productType: createIntervention.productType,
                productColor: createIntervention.color,
                customerFirstName: createIntervention.customer.firstName,
                customerLastName: createIntervention.customer.lastName,
                customerEmail: createIntervention.customer.email,
                customerPhone: createIntervention.customer.phone,
                customerAddress: createIntervention.customer.address,
                customerZipCode: createIntervention.customer.zipCode,
                customerCity: createIntervention.customer.city,
                externalReference: getValues("externalRef"),
                franchiseAmountTTC: franchise,
                thresholdAmountTTC: thresholdAmountTTC,
                shopId: shopId,
                isCentralisation: !proximity,
                repairsIds: createIntervention.repairs.filter((repair) => repair.id).map((repair) => repair.id),
                guaranteeType: createIntervention.guaranteeType,
                guaranteeComment: createIntervention.guaranteeComment,
                guaranteeRepairs: createIntervention.repairs.filter((repair) => !repair.id).map((repair) => repair.category),
                repairsLabels: createIntervention.repairs.map((repair) => i18n.exists('category.' + repair.category) ? t('category.' + repair.category) : repair.category),
            }, {})
                .then((response) => {
                    dispatch(updateInterventionId(response.data.interventionId))
                    setIsLoading(false)
                    setInterventionId(response.data.interventionId)
                    setCreatedIntervention(response.data);
                })

        } else {
            axiosInstance.post("/insurance", {
                    brandName: createIntervention.product.brand.name,
                    modelName: createIntervention.product.model.name,
                    imei: createIntervention.product.imei ? createIntervention.product.imei : "",
                    serial: createIntervention.product.serial,
                    productType: createIntervention.productType,
                    productColor: createIntervention.color,
                    customerFirstName: createIntervention.customer.firstName,
                    customerLastName: createIntervention.customer.lastName,
                    customerEmail: createIntervention.customer.email,
                    customerPhone: createIntervention.customer.phone,
                    customerAddress: createIntervention.customer.address,
                    customerZipCode: createIntervention.customer.zipCode,
                    customerCity: createIntervention.customer.city,
                    externalReference: getValues("externalRef"),
                    franchiseAmountTTC: franchise,
                    thresholdAmountTTC: thresholdAmountTTC,
                    shopId: shopId,
                    isCentralisation: !proximity,
                    repairsIds: createIntervention.repairs.filter((repair) => repair.id).map((repair) => repair.id),
                    guaranteeType: createIntervention.guaranteeType,
                    guaranteeComment: createIntervention.guaranteeComment,
                    guaranteeRepairs: createIntervention.repairs.filter((repair) => !repair.id).map((repair) => repair.category),
                    repairsLabels: createIntervention.repairs.map((repair) => i18n.exists('category.' + repair.category) ? t('category.' + repair.category) : repair.category),
                    damageDate: createIntervention.damageDate
                }
            )
                .then((response) => {
                    dispatch(updateInterventionId(response.data.interventionId))
                    setIsLoading(false)
                    setInterventionId(response.data.interventionId)
                    setCreatedIntervention(response.data);
                })
                .catch((error) => {
                    setIsLoading(false)
                    if (error && error.response && error.response.data.httpCode === 400 && error.response.data.errorCode === 40016) {
                        setError('externalRef', {
                            type: 'custom',
                            message: 'Cette référence externe existe déjà dans la base de donnée'
                        });
                    }
                });
        }
    }

    const handleReferenceInputChange = (event) => {
        const { value } = event.target;
        const newValue = value.replace(/[^a-zA-Z0-9-_]/g, '').toUpperCase();
        if (newValue.length <= 40) {
            setInputValue(newValue);
        }
    };

    useEffect(() => {
        axiosInstance.get("/stats/shops", {
            params: {
                contractType: broker.contractType
            }
        })
            .then(res => {
                setShopCount(res.data.totalResult);
                if (res.data.totalResult === 1) {
                    setShopId(res.data.shops[0].shopId);
                    setValue("shop", res.data.shops[0].name + " (" + res.data.shops[0].shopId + ")");
                } else if (res.data.totalResult === 0) {
                    setProximity(false);
                }
            })
    }, [])

    useEffect(() => {
        setPreviousButtonState({
            disabled: false,
            onPrevious: () => {
                setStep("CUSTOMER_FORM")
            }
        });
    }, [])

    useEffect(() => {
        if (interventionId) {
            setNextButtonState({
                disabled: false,
                label: t('generic.quit'),
                isPrimary: true,
                onNext: () => {
                    dispatch(clearCreationWorkflow())
                    navigate("/")
                }
            })
        } else {
            setNextButtonState({
                disabled: !isValid || isLoading || (proximity && !shopId),
                label: t('page.intervention.validate-request'),
                isPrimary: true,
                onNext: onSubmit
            })
        }
    }, [isDirty, isValid, interventionId, isLoading, shopId, proximity])

    function showChronopost() {
        axiosInstance.get("/followup/" + interventionId + "/chronopost", {responseType: 'blob'})
            .then((response) => {

                // below for a direct download
                // const url = window.URL.createObjectURL(new Blob([response.data]));
                // const link = document.createElement('a');
                // link.href = url;
                // link.setAttribute('download', 'file.pdf');
                // document.body.appendChild(link);
                // link.click();

                const blob = new Blob([response.data], {type: 'application/pdf'});
                const url = window.URL.createObjectURL(blob);
                const newTab = window.open(url, '_blank');
                if (newTab) {
                    newTab.addEventListener('beforeunload', () => {
                        window.URL.revokeObjectURL(url);
                    });
                } else {
                    alert(t('page.intervention.popup'));
                }
            })
    }

    useEffect(() => {
        if (franchiseValue && !/^[0-9]*\,?[0-9]*$/.test(franchiseValue)) {
            setError('franchise', {
                type: 'manual',
                message: t('form.errors.valid-number')
            });
        } else {
            clearErrors('franchise');
        }
    }, [franchiseValue])

    useEffect(() => {
        if (thresholdAmountTTCValue && !/^[0-9]*\,?[0-9]*$/.test(thresholdAmountTTCValue)) {
            setError('thresholdAmountTTC', {
                type: 'manual',
                message: t('form.errors.valid-number')
            });
        } else {
            clearErrors('thresholdAmountTTC');
        }
    }, [thresholdAmountTTCValue])

    if (isLoading) {
        return (
            <><Container textAlign={"center"} style={{paddingTop: "30px"}}><Message floating={true} compact={true}>
                <Message.Content>
                    <Message.Header>{t('page.intervention.save-inprogress')}</Message.Header>
                    {t('page.intervention.dont-quit')}
                </Message.Content><br/>
                <Progress percent={100} active size={"small"}>
                </Progress>
            </Message></Container></>
        )
    } else if (interventionId) {

        return (
            <>
                <Header attached={"top"}>
                    <Icon name='check' color={"teal"}/>
                    <Header.Content>{createdIntervention.reference}</Header.Content>
                </Header>
                <Segment attached={true}>
                    <p>{t('page.intervention.request-saved')} : <a
                        onClick={() => navigate('/followup/' + interventionId)}><b>{createdIntervention.reference}</b></a>
                    </p>

                    {createdIntervention.emailSended &&
                        <p>{t('page.intervention.email-sent')} <b>{createIntervention.customer.email}</b></p>}

                    {createdIntervention.proximity ?
                        <>
                            <p>
                                {t('page.intervention.customer-validation')}
                            </p>

                            <Container textAlign={"center"}>
                                <FontAwesomeIcon style={{marginTop: '20px', marginBottom: '15px'}} size={"4x"}
                                                 icon="fa-regular fa-store"/><br/>
                                {t('page.intervention.repair-shop')} :<br/>
                                <b>{createdIntervention.shopName}</b><br/>
                            </Container>
                        </> :
                        <>
                            <p>
                                {t('page.intervention.repair-center-wait')} :
                                <ul>
                                    {createdIntervention.emailSended && broker.allowTansportVoucherGeneration ?
                                        <li>{t('page.intervention.customer-send')}</li> : null}
                                    {broker.allowTansportVoucherGeneration ?
                                        <li>{t('page.intervention.voucher-download')}</li> : null}
                                    {!broker.allowTansportVoucherGeneration ?
                                        <li>{t('page.intervention.in-charge')}</li> : null}
                                </ul>
                            </p>

                            {broker.contractType !== 'INSURANCE' && <Container textAlign={"center"}>
                                <FontAwesomeIcon style={{marginTop: '20px', marginBottom: '15px'}} size={"4x"}
                                                 icon="fa-regular fa-store"/><br/>
                                {t('page.intervention.repair-center')} :<br/>
                                <b>{createdIntervention.shopName}</b><br/>
                                {broker.allowTansportVoucherGeneration && <Button onClick={showChronopost} primary
                                                                                  style={{marginTop: '20px'}}><FontAwesomeIcon
                                    icon="fa-solid fa-truck-fast"/>&nbsp;&nbsp;{t('page.intervention.print-voucher')}</Button>}
                            </Container>}
                        </>
                    }

                </Segment>

            </>

        )
    }

    return (
        <>
            <PageHeader title={t('page.intervention.additional-infos')}/>

            <Form onSubmit={handleSubmit(onSubmit)}>

                {(shopCount > 0 && broker.centralisationShopId && createIntervention.guaranteeType !== "NGU") || (shopCount > 1) ?
                    <Segment>
                        {shopCount > 0 && broker.centralisationShopId && createIntervention.guaranteeType !== "NGU" ? <>
                            <Form.Field required={true}>
                                <label>{t('page.intervention.sending-choice')}</label>
                            </Form.Field>
                            <Form.Group widths='equal'>
                                <Form.Field>
                                    <Checkbox
                                        radio
                                        label={t('page.intervention.proximity')}
                                        name='checkboxRadioGroup'
                                        value={true}
                                        checked={proximity}
                                        onChange={(e, data) => setProximity(data.value)}
                                    />
                                    <p className="imei-input-info-text">
                                        <FontAwesomeIcon icon="fa-regular fa-circle-info"/>
                                        &nbsp;&nbsp;{t('page.intervention.drop-off')}
                                    </p>
                                </Form.Field>
                                <Form.Field>
                                    <Checkbox
                                        radio
                                        label={t('page.intervention.centralization')}
                                        name='checkboxRadioGroup'
                                        value={false}
                                        checked={!proximity}
                                        onChange={(e, data) => setProximity(data.value)}
                                    />
                                    <p className="imei-input-info-text"><FontAwesomeIcon
                                        icon="fa-regular fa-circle-info"/>&nbsp;&nbsp;{t('page.intervention.shipment')}</p>
                                </Form.Field>
                            </Form.Group>
                        </> : null}

                        {shopCount > 1 && proximity && <Form.Field required={true}>
                            <label htmlFor="shop">{t('page.intervention.store-choice')}</label>
                            <input name="shop" id={"shop"} style={{cursor: "pointer"}}
                                   onClick={() => setSelectShopModal(true)}
                                   {...register('shop', {required: false})}
                                   placeholder={t('page.intervention.store-choice')}/>
                        </Form.Field>}
                    </Segment> : null}

                <Form.Field required={false}>
                    <label htmlFor="externalRef">{t('generic.external-id')}</label>
                    <input name="externalRef" id={"externalRef"}
                           defaultValue={createIntervention.conditions?.externalRef}
                           {...register('externalRef', {required: false})}
                           placeholder={t('generic.external-id')}
                           value={inputValue}
                           onChange={handleReferenceInputChange}
                    />
                    <span style={{color: 'red'}}>{errors.externalRef && <p>{errors.externalRef.message}</p>}</span>
                    <p className="imei-input-info-text"><FontAwesomeIcon
                        icon="fa-regular fa-circle-info"/>&nbsp;&nbsp;{t('page.intervention.external-id-label')}</p>
                </Form.Field>

                {createIntervention.guaranteeType !== "NGU" &&
                    <>

                        {broker.thresholdManagement && <Form.Field required={broker.thresholdMandatory}>
                            <label htmlFor="thresholdAmountTTC">{t('page.intervention.max-repair-price')}</label>
                            <input name="thresholdAmountTTC" id={"thresholdAmountTTC"}
                                   required={broker.thresholdMandatory}
                                   defaultValue={createIntervention.conditions?.thresholdAmountTTC}
                                   {...register('thresholdAmountTTC', {
                                       required: broker.thresholdMandatory,
                                       pattern: {
                                           value: /^[0-9]*\,?[0-9]*$/,
                                           message: t('form.errors.valid-number')
                                       }
                                   })}
                                   placeholder={t('page.intervention.max-repair-price')}/>
                            {errors.thresholdAmountTTC &&
                                <span style={{color: 'red'}}>{errors.thresholdAmountTTC.message}</span>}
                            <p className="imei-input-info-text">
                                <FontAwesomeIcon icon="fa-regular fa-circle-info"/>
                                &nbsp;&nbsp;{t('page.intervention.max-repair-price-label')}
                            </p>
                        </Form.Field>}

                        {broker.franchiseManagement && <Form.Field required={false}>
                            <label htmlFor="franchise">{t('page.intervention.deductible')}</label>
                            <input name="franchise" defaultValue={createIntervention.conditions?.franchise}
                                   {...register('franchise', {
                                       required: false,
                                       pattern: {
                                           value: /^[0-9]*\,?[0-9]*$/,
                                           message: t('form.errors.valid-number')
                                       }
                                   })}
                                   placeholder={t('page.intervention.franchise')}/>
                            {errors.franchise && <span style={{color: 'red'}}>{errors.franchise.message}</span>}
                            <p className="imei-input-info-text">
                                <FontAwesomeIcon icon="fa-regular fa-circle-info"/>&nbsp;&nbsp;
                                {t('page.intervention.deductible-label')}</p>
                        </Form.Field>}

                    </>
                }
            </Form>


            <Modal open={selectShopModal} onClose={() => setSelectShopModal(false)}>
                <Modal.Header>{t('page.intervention.store-choice')}</Modal.Header>
                <Modal.Content>
                    <SelectShoptable
                        zipCode={createIntervention.customer.zipCode}
                        onSuccess={(shop) => {
                            setSelectShopModal(false);
                            toastSuccess(t('page.intervention.store-choice'));
                            setValue("shop", shop.name + " (" + shop.shopId + ")");
                            setShopId(shop.shopId);
                        }}
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button negative onClick={() => setSelectShopModal(false)}>
                        {t('generic.cancel')}
                    </Button>
                </Modal.Actions>
            </Modal>

        </>
    );
}


export default FinalValidationComponent;
