import {
    Button,
    Container,
    Icon, Menu, Message
} from 'semantic-ui-react';
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import axiosInstance from "../../utils/axiosInstance";
import TableFleetProduct from "./TableFleetProduct";
import FileImportButton from "../../components/FileImportButton/FileImportButton";
import FileExportButton from "../../components/FileExportButton/FileExportButton";
import StandardLayout from "../../layouts/StandardLayout";
import {getAllProductsByFleetId} from "./FleetService";

function FleetProductPage() {

    const [products, setProducts] = useState([]);
    const [fleetName, setFleetName] = useState('');
    const {fleetId} = useParams();
    let navigate = useNavigate();

    const menuChange = (path) => {
        navigate(path);
    }

    useEffect(() => {
        axiosInstance.get("fleets/" + fleetId)
            .then((response) => {
                setFleetName(response.data.name);
            });
        getAllProductsByFleetId(setProducts,fleetId);
    }, [products]);

    return <StandardLayout title={'Liste des produits pour la flotte ' +fleetName } page={  <Container>

        <Menu borderless={true} text>
            <Menu.Menu position='right'>
                <Menu.Item>
                    {<Button
                        floated='right'
                        labelPosition='left'
                        icon
                        primary
                        size='small'
                        onClick={() => menuChange('/fleets/'+fleetId+'/products/add')}>
                        <Icon name='add'/> Créer un produit
                    </Button>}
                </Menu.Item>
                <Menu.Item>
                    <FileImportButton url="/fleets/products/excel"
                                      onSuccess={() => getAllProductsByFleetId(setProducts, fleetId)}/>
                </Menu.Item>
                <Menu.Item>
                    <FileExportButton url="/fleets/products/export"
                                      datas={{fleetId: fleetId}}
                                      nameFile="export_fleet_product"/>
                </Menu.Item>
            </Menu.Menu>
        </Menu>
        {products.length === 0 ?
            <Message warning>
                <Message.Content>
                    <label>La liste des produits est vide</label>
                </Message.Content>
            </Message>
            : <TableFleetProduct products={products} setProducts={setProducts}></TableFleetProduct>}

    </Container>}/>

}

export default FleetProductPage;
