import {Button, Grid, Icon, Statistic,} from 'semantic-ui-react'
import React, {useEffect, useState} from "react";
import axiosInstance from "../../utils/axiosInstance";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

function StatInProgressCountCardComponent() {

    const {t} = useTranslation();
    const [deviceInProgressCount, setDeviceInProgressCount] = useState(0);
    const brokerCode = useSelector(state => state.user.broker.code);
    const navigate = useNavigate();

    useEffect(() => {
        axiosInstance.get("/stats/inprogress/count")
            .then(res => {
                setDeviceInProgressCount(res.data);
            })
    }, [brokerCode]);

    return (
        <div className='stat-card'>

            <Grid columns={2} centered style={{height: '130px'}}>
                <Grid.Column stretched width={4} verticalAlign={"middle"} textAlign={"center"}>
                    <Icon.Group size='massive'>
                        <Icon name='mobile alternate'/>
                        <Icon corner='top right' name='cog' color={"teal"}/>
                    </Icon.Group>
                </Grid.Column>
                <Grid.Column verticalAlign={"middle"} textAlign={"center"}>

                    <Statistic horizontal>
                        <Statistic.Value>{deviceInProgressCount}</Statistic.Value>
                        <Statistic.Label>{t('page.home.inprogress')}</Statistic.Label>
                    </Statistic>

                    <Button primary={true} onClick={() => navigate("/followup")}>
                        {t('page.home.inprogress-btn')}
                    </Button>
                </Grid.Column>
            </Grid>
        </div>
    );
}


export default StatInProgressCountCardComponent;
