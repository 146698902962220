import axiosInstance from "../../utils/axiosInstance";

export function getAllFleets(setFleets, setIsVisible) {
    axiosInstance.get("fleets")
        .then((response) => {
            setFleets(response.data);
            setIsVisible(true)
        });
}

export function getAllProductsByFleetId(setProducts, fleetId) {
    if (fleetId !== undefined && fleetId !== null) {
        axiosInstance.get("fleets/products", {
            params: {fleetId: fleetId}
        })
            .then((response) => {
                setProducts(response.data);
            });
    }
}
