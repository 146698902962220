import {Button, Container, Form, Grid, Image} from 'semantic-ui-react'

import React, {useState} from 'react';

import {useDispatch} from "react-redux";
import {useNavigate} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import axiosLoginInstance from "../utils/axiosLoginInstance";
import {toastSuccess} from "../reducers/Global/global.actions";
import {LOGIN_ERROR} from "../reducers/User/user.types";
import logo from "../assets/LogoSaveBleu.png";

function ForgetPasswordPage(props) {
    const {t} = useTranslation();

    const [login, setLogin] = useState("");
    const [email, setEmail] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    let navigate = useNavigate();
    const dispatch = useDispatch();

    const resetAction = () => {
        setIsLoading(true);
        axiosLoginInstance.post(process.env.REACT_APP_BACKOFFICE_URL + "/partners/v1/newpwd", {
            login: login,
            email: email
        })
            .then((response) => {
                setIsLoading(false);
                toastSuccess(t('page.login.password-change-success'))
                dispatch({
                    type: LOGIN_ERROR,
                    loginErrorMessage: ""
                });
                navigate("/")
            })
            .catch((error) => {
                setIsLoading(false);
                dispatch({
                    type: LOGIN_ERROR,
                    loginErrorMessage: t('page.login.password-change-error')
                });
                navigate("/")
            });
    }

    return (
        <Grid textAlign='center' style={{height: '100vh'}} verticalAlign='middle'>
            <Grid.Column className="login-card" style={{
                width: 650,
                background: 'white',
                paddingTop: '40px',
                paddingBottom: '40px',
                paddingLeft: '80px',
                paddingRight: '80px',
                verticalAlign: "middle"
            }} textAlign={"center"}>

                <Image onClick={() => navigate("/")} src={logo} centered/>

                <span style={{fontSize: '20px', fontWeight: '900', paddingBottom: '28px !important', lineHeight: '30px'}}>
                    {t('page.login.reset.title')}
                </span>

                <span style={{marginTop: '60px'}}><br/>
                    {t('page.login.reset.subtitle1')}
                     <br/>
                    {t('page.login.reset.subtitle2')}.
                </span>

                <Container style={{maxWidth: '300px !important'}}>
                    <Form size='large' style={{marginTop: '40px', marginBottom: '50px'}}>
                        <Form.Input fluid icon='user' iconPosition='left' placeholder={t("page.login.username")}
                                    value={login}
                                    onChange={(e, {name, value}) => setLogin(value)}/>
                        <Form.Input
                            fluid
                            icon='mail'
                            value={email}
                            iconPosition='left'
                            placeholder={t('generic.email')}
                            onChange={(e, {name, value}) => setEmail(value)}
                        />
                        <Button disabled={isLoading} loading={isLoading} color='teal' fluid size='large'
                                onClick={resetAction}>
                            {t('page.login.reset.validate')}
                        </Button>
                    </Form>
                </Container>

            </Grid.Column>
        </Grid>
    );

}

export default ForgetPasswordPage
