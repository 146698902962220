import {Button, Container} from 'semantic-ui-react';
import React, {useEffect, useState} from "react";
import SelectProductTypeComponent from "../components/CreateIntervention/SelectProductTypeComponent";
import {useDispatch, useSelector} from "react-redux";
import SelectModelComponent from "../components/CreateIntervention/SelectModelComponent";
import SelectColorComponent from "../components/CreateIntervention/SelectColorComponent";
import {useNavigate, useParams} from "react-router-dom";
import DamageTypeComponent from "../components/CreateIntervention/DamageTypeComponent";
import SelectRepairsComponent from "../components/CreateIntervention/SelectRepairsComponent";
import CustomerFormComponent from "../components/CreateIntervention/CustomerFormComponent";
import FinalValidationComponent from "../components/CreateIntervention/FinalValidationComponent";
import SelectModelByBrandModelComponent from "../components/CreateIntervention/SelectModelByBrandModelComponent";
import SelectShopComponent from "../components/CreateIntervention/SelectShopComponent";
import {clearCreationWorkflow, updateGuaranteeType} from "../reducers/Create/create.actions";
import SelectGuaranteeComponent from "../components/CreateIntervention/SelectGuaranteeComponent";
import NewResumeComponent from "../components/CreateIntervention/NewResumeComponent";
import {useTranslation} from "react-i18next";

function CreateInterventionPage() {

    const {t} = useTranslation();

    const navigate = useNavigate();
    const {fleetProductId} = useParams();
    const dispatch = useDispatch();
    const broker = useSelector(state => state.user.broker);

    const createIntervention = useSelector(state => state.create);
    const [step, setStep] = useState("SELECT_PRODUCT_TYPE");

    const mapOfStepsAndProgress = {
        "SELECT_PRODUCT_TYPE": 1,
        "IMEI_SELECTOR": 2,
        "MODEL_SELECTOR": 2,
        "SELECT_COLOR": 3,
        "CHECK_DAMAGE": 4,
        "SELECT_GUARANTEE": 5,
        "SELECT_REPAIRS": 5,
        "CUSTOMER_FORM": 6,
        "FINAL_VALID": 7,
        "SELECT_SHOP": 7
    }

    const [previousButtonState, setPreviousButtonState] = useState({
        disabled: true,
        onPrevious: () => {
            console.log("should not happen 1")
        }
    });

    const [nextButtonState, setNextButtonState] = useState({
        disabled: false,
        isPrimary: true,
        label: t('generic.next'),
        onNext: () => {
            console.log("should not happen 2")
        }
    });

    const onBackButtonEvent = (e) => {
        e.preventDefault();
        if (step === "SELECT_PRODUCT_TYPE") {
            navigate('/');
        } else {
            previousButtonState.onPrevious()
        }
    }

    useEffect(() => {
        if (fleetProductId === undefined || fleetProductId === null) {
            dispatch(clearCreationWorkflow())
        } else {
            if (createIntervention.color === undefined || createIntervention.color === null) {
                setStep("SELECT_COLOR")
            } else {
                setStep("CHECK_DAMAGE")
            }
        }
        if (broker.contractType === "INSURANCE") {
            dispatch(updateGuaranteeType("INSURANCE"))
        } else if (!broker.sgCoverBroker) {
            dispatch(updateGuaranteeType("NGU"))
        }
    }, [])

    useEffect(() => {
        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', onBackButtonEvent);
        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        };
    }, [previousButtonState]);


    return <div className="container">
        <div className={mapOfStepsAndProgress[step] > 2 ? "create-inter-bloc-gauche" : ""}>

            <div className={`slide-in-out ${step === "SELECT_PRODUCT_TYPE" ? 'show' : ''}`}>
                {step === "SELECT_PRODUCT_TYPE" && <SelectProductTypeComponent
                    setPreviousButtonState={setPreviousButtonState}
                    setNextButtonState={setNextButtonState}
                    setStep={setStep}/>}
            </div>

            <div className={`slide-in-out ${step === "IMEI_SELECTOR" ? 'show' : ''}`}>
                {step === "IMEI_SELECTOR" && <SelectModelComponent
                    setPreviousButtonState={setPreviousButtonState}
                    setNextButtonState={setNextButtonState}
                    setStep={setStep}/>}
            </div>

            <div className={`slide-in-out ${step === "MODEL_SELECTOR" ? 'show' : ''}`}>
                {step === "MODEL_SELECTOR" && <SelectModelByBrandModelComponent
                    setPreviousButtonState={setPreviousButtonState}
                    setNextButtonState={setNextButtonState}
                    setStep={setStep}/>}
            </div>

            <div className={`slide-in-out ${step === "SELECT_COLOR" ? 'show' : ''}`}>
                {step === "SELECT_COLOR" && <SelectColorComponent
                    setPreviousButtonState={setPreviousButtonState}
                    setNextButtonState={setNextButtonState}
                    setStep={setStep}/>}
            </div>

            <div className={`slide-in-out ${step === "CHECK_DAMAGE" ? 'show' : ''}`}>
                {step === "CHECK_DAMAGE" && <DamageTypeComponent
                    setPreviousButtonState={setPreviousButtonState}
                    setNextButtonState={setNextButtonState}
                    setStep={setStep}/>}
            </div>

            <div className={`slide-in-out ${step === "SELECT_GUARANTEE" ? 'show' : ''}`}>
                {step === "SELECT_GUARANTEE" && <SelectGuaranteeComponent
                    setPreviousButtonState={setPreviousButtonState}
                    setNextButtonState={setNextButtonState}
                    setStep={setStep}/>}
            </div>

            <div className={`slide-in-out ${step === "SELECT_REPAIRS" ? 'show' : ''}`}>
                {step === "SELECT_REPAIRS" && <SelectRepairsComponent
                    setPreviousButtonState={setPreviousButtonState}
                    setNextButtonState={setNextButtonState}
                    setStep={setStep}/>}
            </div>

            <div className={`slide-in-out ${step === "CUSTOMER_FORM" ? 'show' : ''}`}>
                {step === "CUSTOMER_FORM" && <CustomerFormComponent
                    setPreviousButtonState={setPreviousButtonState}
                    setNextButtonState={setNextButtonState}
                    setStep={setStep}/>}
            </div>

            <div className={`slide-in-out ${step === "FINAL_VALID" ? 'show' : ''}`}>
                {step === "FINAL_VALID" && <FinalValidationComponent
                    setPreviousButtonState={setPreviousButtonState}
                    setNextButtonState={setNextButtonState}
                    setStep={setStep}/>}
            </div>

            <div className={`slide-in-out ${step === "SELECT_SHOP" ? 'show' : ''}`}>
                {step === "SELECT_SHOP" && <SelectShopComponent
                    setPreviousButtonState={setPreviousButtonState}
                    setNextButtonState={setNextButtonState}
                    setStep={setStep}/>}
            </div>
            <Container style={{
                paddingTop: '30px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
            }}>
                {<Button disabled={Boolean(createIntervention.interventionId || previousButtonState.disabled)}
                         onClick={previousButtonState.onPrevious}>{t('generic.previous')}</Button>}
                <Button primary={nextButtonState.isPrimary} disabled={nextButtonState.disabled}
                        onClick={nextButtonState.onNext}>{nextButtonState.label}</Button>
            </Container>
        </div>
        {mapOfStepsAndProgress[step] > 2 &&
            <div className="create-inter-bloc-droite">
                <NewResumeComponent step={step}/>
            </div>
        }
    </div>

}

export default CreateInterventionPage;
