import {ADD_TO_LOGGING, RESET_LOGGING_LIST, SET_LOADING_MESSAGE} from "./global.types";
import {toast} from "react-toastify";

export function loading(message) {
    return async function (dispatch) {
        dispatch({type: SET_LOADING_MESSAGE, dimmerMessage: message});
    }
}

export function addToLogging(object) {
    return async function (dispatch) {
        dispatch({type: ADD_TO_LOGGING, object: object, date: Date.now()});
    }
}

export function resetLoggingList() {
    return async function (dispatch) {
        dispatch({type: RESET_LOGGING_LIST});
    }
}

export function toastSuccess(message) {
    const toastOptions = {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    };
    toast.success(message, toastOptions)
}

export function toastCopySuccess(message) {
    const toastOptions = {
        position: "top-center",
        autoClose: 300,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
    };
    toast.info(message, toastOptions)
}