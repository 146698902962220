import {Card, Grid, Icon} from 'semantic-ui-react';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {updateColor} from '../../reducers/Create/create.actions';
import {useTranslation} from 'react-i18next';
import PageHeader from '../PageHeader/PageHeader';

function SelectModelComponent(props) {

    const dispatch = useDispatch();
    const createIntervention = useSelector((state) => state.create);
    const {setStep, setPreviousButtonState, setNextButtonState} = props;
    const {t} = useTranslation();

    useEffect(() => {
        setPreviousButtonState({
            disabled: false,
            onPrevious: () => {
                setStep(createIntervention.product.imei ? 'IMEI_SELECTOR' : 'MODEL_SELECTOR');
            },
        });
        setNextButtonState({
            disabled: !createIntervention.color,
            label: t('generic.next'),
            isPrimary: true,
            onNext: () => handleColorClick(createIntervention.color),
        });
    }, []);

    const colorMappings = {
        WHITE: '#FFFFFF',
        BLACK: '#000000',
        SILVER: '#C0C0C0',
        GREY: '#808080',
        BLUE: '#0000FF',
        RED: '#FF0000',
        GOLD: '#FFD700',
        GREEN: '#008000',
        PINK: '#FFC0CB',
        ORANGE: '#FFA500',
        PURPLE: '#800080',
        YELLOW: '#FFFF00',
        BROWN: '#A52A2A',
        PINKGOLD: '#FF91A4',
        ANTHRACITE: '#333333'
    };

    function handleColorClick(color) {
        dispatch(updateColor(color));
        setStep('CHECK_DAMAGE');
    }

    const colors = Object.keys(colorMappings);

    return (
        <>
            <PageHeader title={t('page.intervention.color-choice')}/>

            <Card.Group centered={true} textAlign={'center'} stackable={false}>
                {colors.map((color) => (
                    <Card style={{width: '150px'}} key={color}
                          className={createIntervention.color === color ? "color-card-selected" : "color-card"}
                          onClick={() => handleColorClick(color)}
                    >
                        <Card.Content style={{margin: '0px', padding: '0px'}}>
                            <Grid style={{margin: '0px', padding: '0px'}}>
                                <Grid.Row style={{margin: '0px', padding: '0px', height: '40px'}}>
                                    <Grid.Column className={color === 'WHITE' ? "color-white" : ""} width={4}
                                                 textAlign={'center'} style={{
                                        backgroundColor: colorMappings[color],
                                        margin: '0px',
                                        padding: '0px',
                                    }}>
                                    </Grid.Column>
                                    <Grid.Column width={12} textAlign={'center'} verticalAlign={"middle"}>
                                        {t('colors.' + color)}
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Card.Content>
                    </Card>
                ))}
                <Card style={{width: '150px'}} key={'OTH'}
                      className={createIntervention.color === 'OTH' ? "color-card color-card-selected" : "color-card"}
                      onClick={() => handleColorClick('OTH')}
                >
                    <Card.Content style={{margin: '0px', padding: '0px'}}>
                        <Grid style={{margin: '0px', padding: '0px'}}>
                            <Grid.Row style={{margin: '0px', padding: '0px', height: '40px'}}>
                                <Grid.Column className={"color-white"} width={4}
                                             textAlign={'center'} verticalAlign={"middle"} style={{
                                    backgroundColor: colorMappings['OTH'],
                                    margin: '0px',
                                    padding: '0px',
                                    height: '40px'
                                }}><Icon name={"question"} size={"large"} style={{marginTop: '10px', marginLeft: '3px'}}/>
                                </Grid.Column>
                                <Grid.Column width={12} textAlign={'center'} verticalAlign={"middle"}>
                                    {t('colors.OTH')}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Card.Content>
                </Card>
            </Card.Group>
        </>
    );
}

export default SelectModelComponent;
