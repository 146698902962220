import {useEffect, useState} from "react";
import axiosInstance from "../utils/axiosInstance";

const useAjax = (url, method = 'get', body) => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if(url) {
            setIsLoading(true);
            axiosInstance({
                method,
                url,
                data: body
            })
                .then(response => {
                    setIsLoading(false);
                    setData(response.data);
                });
        }
    }, [url]);

    return [data, isLoading];
}

export default useAjax;