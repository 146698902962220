import {Grid, Icon, Statistic,} from 'semantic-ui-react'
import React, {useEffect, useState} from "react";
import axiosInstance from "../../utils/axiosInstance";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

function StatRepairedCountCardComponent() {

    const {t} = useTranslation();
    const [deviceRepairedCount, setDeviceRepairedCount] = useState(0);
    const brokerCode = useSelector(state => state.user.broker.code);

    useEffect(() => {
        axiosInstance.get("/stats/repairs/count")
            .then(res => {
                setDeviceRepairedCount(res.data);
            })
    }, [brokerCode]);

    return (
        <div className='stat-card'>

            <Grid columns={2} centered style={{height: '130px'}}>
                <Grid.Column stretched width={4} verticalAlign={"middle"} textAlign={"center"}>
                    <Icon.Group size='massive'>
                        <Icon name='mobile alternate'/>
                        <Icon corner='top right' name='check' color={"teal"}/>
                    </Icon.Group>
                </Grid.Column>
                <Grid.Column verticalAlign={"middle"} textAlign={"center"}>

                    <Statistic>
                        <Statistic.Value>{deviceRepairedCount}</Statistic.Value>
                        <Statistic.Label> {t('page.home.repaired')}</Statistic.Label>
                    </Statistic>

                </Grid.Column>
            </Grid>

        </div>
    );
}


export default StatRepairedCountCardComponent;
