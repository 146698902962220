import React, {useEffect, useState} from "react";
import {Button, Checkbox, Form, Message} from 'semantic-ui-react'
import {useNavigate, useParams} from "react-router-dom";
import axiosInstance from "../../utils/axiosInstance";
import {useTranslation} from "react-i18next";

function FleetForm(prop) {

    const [fleetId, setFleetId] = useState("");
    const [name, setName] = useState("");
    const [productType, setProductType] = useState("");
    const [enabled, setEnabled] = useState(true);
    const [control, setControl] = useState(false);
    let navigate = useNavigate();
    const {t} = useTranslation();
    const {productTypeCode} = useParams();

    const options = [
        { key: 'SMA',text: t('product.type.SMA'), value: "SMA"},
        { key: 'TAB', text: t('product.type.TAB'), value: "TAB"},
        { key: 'CSL', text: t('product.type.CSL'), value: "CSL"},
        { key: 'COM', text: t('product.type.COM'), value: "COM"},
        { key: 'LPT', text: t('product.type.LPT'), value: "LPT"},
    ];

    useEffect(() => {
        if (prop.fleet !== undefined && prop.fleet !== null) {
            setFleetId(prop.fleet.fleetId)
            setName(prop.fleet.name);
            setProductType(prop.fleet.productType);
            setEnabled(prop.fleet.enabled);
        } else {
            setName("");
            setProductType("");
            setEnabled(true);
        }
    }, [prop.fleet]);

    useEffect(() => {
        setProductType(productTypeCode)
    }, []);

    const annuler = () => {
        navigate("/fleets");
    };

    const submit = () => {
        const data = {
            name: name,
            productType: productType,
            enabled:enabled,
            fleetId: fleetId,
        };
        if (name === "" || productType === "" ) {
            setControl(false)
        } else if (fleetId !== "") {
            setControl(false)
            axiosInstance.put("/fleets/" +fleetId, data)
                .then(res => {
                    navigate("/fleets");
                }).catch(e => {
                console.log(e);
            })
        } else {
            setControl(false)
            axiosInstance.post("/fleets", data)
                .then(res => {
                    navigate("/fleets");
                }).catch(e => {
                console.log(e);
            })
        }
    };

    return <div>
        <Message
            warning
            header='Remplir les champs'
            content='Merci de saisir un nom et type de produit.'
            hidden={control}
        />

        <Form>
            <Form.Field required>
                <label>{'Nom de la flotte'}</label>
                <Form.Input placeholder={'Nom de la flotte'}
                            value={name}
                            disabled={(false) ? "disabled" : ""}
                            required={true}
                            onChange={(e, {name, value}) => setName(value)}/>

                <Form.Select fluid
                             label={t('page.fleet.product-type')}
                             name="productType"
                             placeholder={t('page.fleet.select-product')}
                             value={productType}
                             required
                             clearable
                             options={options}
                             onChange={async (e, {name, value}) => {
                                 setProductType(value)
                             }}
                />
                {prop.fleet !== undefined && prop.fleet !== null ?<>
                <label>{'Actif'}</label>
                <div className="ui toggle checkbox">
                    <Checkbox
                        value={enabled}
                        checked={enabled}
                        onChange={(e, {label, value}) => setEnabled(!enabled)}/>
                </div> </>: null}
            </Form.Field>

            <Button type='' onClick={annuler}>{t('generic.cancel')}</Button>
            <Button primary onClick={submit}>{t('generic.validate')}</Button>
        </Form>
    </div>
}

export default FleetForm;
