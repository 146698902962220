import React from "react";
import { Button, Icon } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import StatRepairedCountCardComponent from "../components/StatCardComponent/StatRepairedCountCardComponent";
import StatShopComponent from "../components/StatCardComponent/StatShopComponent";
import StatInProgressCountCardComponent from "../components/StatCardComponent/StatInProgressCountCardComponent";
import {useTranslation} from "react-i18next";

const HomePage = () => {

    const {t} = useTranslation();
    let navigate = useNavigate();

    return (
        <div style={{ display: "flex", flexWrap: "wrap", gap: "20px", justifyContent: "center" }}>
            <div
                className="create-inter-cta"
                style={{ minWidth: "480px", maxWidth: "520px", flex: "1" }}
            >
                <div className="create-inter-content">
                    <h2>{t('page.home.create')}</h2>
                    <br />
                    <Button
                        onClick={() => navigate("/create")}
                        animated="fade"
                        circular
                        color={"teal"}
                        size={"massive"}
                    >
                        <Button.Content visible>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Icon name="add" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </Button.Content>
                        <Button.Content hidden>{t('page.home.start')}</Button.Content>
                    </Button>
                </div>
            </div>
            <div style={{ minWidth: "480px", maxWidth: "520px", flex: "1" }}>
                <StatShopComponent />
            </div>
            <div style={{ minWidth: "480px", maxWidth: "520px", flex: "1" }}>
                <StatInProgressCountCardComponent />
            </div>
            <div style={{ minWidth: "480px", maxWidth: "520px", flex: "1" }}>
                <StatRepairedCountCardComponent />
            </div>
        </div>
    );
};

export default HomePage;
