import {useParams} from "react-router-dom";
import axiosInstance from "../../utils/axiosInstance";
import {useEffect, useState} from "react";
import ProductForm from "./ProductForm";

function ProductUpdate() {

    const [fleetProduct, setFleetProduct] = useState(null);
    const {fleetId} = useParams();
    const {fleetProductId} = useParams();

    useEffect(() => {
        axiosInstance.get("/fleets/products?fleetId=" + fleetId+"&&fleetProductId="+fleetProductId)
            .then((response) => {
                setFleetProduct(response.data[0]);
            })
            .catch((error) => {
                console.log(error)
            });
    }, []);

    return  <ProductForm product={fleetProduct}/>

}

export default ProductUpdate