import {Grid} from 'semantic-ui-react'
import React, {useEffect} from "react";
import {selectProductType} from "../../reducers/Create/create.actions";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import PageHeader from "../PageHeader/PageHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function SelectProductTypeComponent(props) {

    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {setStep, setPreviousButtonState, setNextButtonState} = props;
    const createIntervention = useSelector(state => state.create);

    const handleProductTypeClick = (productType) => {
        dispatch(selectProductType(productType));
        if (productType === "COM" || productType === "LPT" || productType === "CSL" || productType === "TAB") {
            setStep("MODEL_SELECTOR");
        } else {
            setStep("IMEI_SELECTOR");
        }
    }

    const productTypes = [
        {productType: "SMA", name: "Smartphone", icon: "fa-regular fa-mobile"},
        {productType: "TAB", name: "Tablette", icon: "fa-regular fa-tablet"},
        {productType: "CSL", name: "Console", icon: "fa-regular fa-gamepad-modern"},
        {productType: "LPT", name: "Ordinateur", icon: "fa-regular fa-laptop"},
    ];

    useEffect(() => {
        setPreviousButtonState({
            disabled: true,
            onPrevious: null
        });
        setNextButtonState({
            disabled: !createIntervention.productType,
            label: t('generic.next'),
            isPrimary: true,
            onNext: () => handleProductTypeClick(createIntervention.productType)
        });
    }, [])


    return (
        <>
            <PageHeader title={t('page.intervention.title')}/>

            <Grid columns='equal' verticalAlign={"middle"} centered stackable padded={"vertically"}>
                {productTypes.map((productType) => (
                    <Grid.Column textAlign={"center"} verticalAlign={"middle"} key={productType.productType}>
                        <div
                            className={"select-product-type-component-card" + (createIntervention.productType === productType.productType ? " selected" : "")}
                            onClick={() => handleProductTypeClick(productType.productType)}>
                            <FontAwesomeIcon icon={productType.icon} size={"4x"}/>
                            <div
                                className="select-product-type-component-text">{t('product.type.' + productType.productType)}</div>
                        </div>
                    </Grid.Column>
                ))}
            </Grid>
        </>
    );
}


export default SelectProductTypeComponent;
