import {
    Container, Segment,
} from 'semantic-ui-react';
import React from "react";
import FleetForm from "./FleetForm";

function FleetAdd() {

    return  <Container>
        <Segment attached>
            <FleetForm></FleetForm>
        </Segment>
    </Container>

}

export default FleetAdd;
