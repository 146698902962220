import {
    Button,
    Container,
    Icon, Menu, Progress
} from 'semantic-ui-react';
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import TableFleet from "./TableFleet";
import axiosInstance from "../../utils/axiosInstance";
import FleetFirstAdd from "./FleetFirstAdd";
import {getAllFleets} from "./FleetService";

function FleetPage() {

    const [fleets, setFleets] = useState([]);
    const [isVisible, setIsVisible] = useState(false);
    let navigate = useNavigate();

    useEffect(() => {
        getAllFleets(setFleets, setIsVisible);
    }, [fleets]);

    return <Container>
        {isVisible && <>
            <Menu borderless={true} text>
                <Menu.Menu position='right'>
                    <Menu.Item>
                        {fleets.length === 0 ? null :
                            <Button
                                floated='right'
                                labelPosition='left'
                                icon
                                primary
                                size='small'
                                onClick={() => navigate('/fleets/add')}>
                                <Icon name='add'/> Créer une flotte
                            </Button>}
                    </Menu.Item>
                </Menu.Menu>
            </Menu>

            {fleets.length == 0 ? <FleetFirstAdd></FleetFirstAdd> :
                <TableFleet fleets={fleets} setFleets={setFleets}></TableFleet>}
        </>}
    </Container>

}

export default FleetPage;
