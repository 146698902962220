export function isRoleBrokerAdmin(user) {
    return isRoleSaveAdmin(user) || user.roles.includes("ROLE_BROKER_ADMIN");
}

export function isNotInsuranceContract(user) {
    return user.broker.contractType !== "INSURANCE"
}

export function isRoleSaveAdmin(user) {
    return user.roles.includes("ROLE_SAVE_ADMIN");
}

export function isDevEnv() {
    return process.env.REACT_APP_ENVIRONMENT === 'development';
}

export function isProdEnv() {
    return process.env.REACT_APP_ENVIRONMENT === 'production';
}

export function isPreprodEnv() {
    return process.env.REACT_APP_ENVIRONMENT === 'preproduction';
}

export function getTenant() {
    return process.env.REACT_APP_TENANT_ID ? process.env.REACT_APP_TENANT_ID : 'fr';
}