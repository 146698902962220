import React from "react";

const colorStatusMap = new Map();

colorStatusMap.set(
    "WHITE", {
        color: '#FFFFFF',
    }
);

colorStatusMap.set(
    "BLACK", {
        color: '#000000',
    }
);

colorStatusMap.set(
    "SILVER", {
        color: '#C0C0C0',
    }
);

colorStatusMap.set(
    "GREY", {
        color: '#808080',
    }
);

colorStatusMap.set(
    "BLUE", {
        color: '#0000FF',
    }
);

colorStatusMap.set(
    "RED", {
        color: '#FF0000',
    }
);

colorStatusMap.set(
    "GOLD", {
        color: '#FFD700',
    }
);

colorStatusMap.set(
    "GREEN", {
        color: '#008000',
    }
);

colorStatusMap.set(
    "PINK", {
        color: '#FFC0CB',
    }
);

colorStatusMap.set(
    "ORANGE", {
        color: '#FFA500',
    }
);

colorStatusMap.set(
    "PURPLE", {
        color: '#800080',
    }
);

colorStatusMap.set(
    "YELLOW", {
        color: '#FFFF00',
    }
);

colorStatusMap.set(
    "BROWN", {
        color: '#A52A2A',
    }
);

colorStatusMap.set(
    "PINKGOLD", {
        color: '#FF91A4',
    }
);

colorStatusMap.set(
    "ANTHRACITE", {
        color: '#333333'
    }
);

export default colorStatusMap;
