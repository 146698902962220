import {combineReducers} from 'redux';

import userReducer from '../reducers/User/user.reducer';
import globalReducer from "../reducers/Global/global.reducer";
import createReducer from "../reducers/Create/create.reducer";

const rootReducer = combineReducers({
    user: userReducer,
    global: globalReducer,
    create: createReducer,
});

export default rootReducer;