import React from "react"
import {Card, Message} from 'semantic-ui-react'
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

function FleetFirstAdd() {
    let navigate = useNavigate();
    const {t} = useTranslation();
    const handleProductTypeClick = (productType) => {
        navigate("/fleets/add/productType/"+ productType)
    }

    const productTypes = [
        {productType: "SMA", name: "Smartphone"},
        {productType: "TAB", name: "Tablette"},
        {productType: "CSL", name: "Console"},
        {productType: "COM", name: "Ordinateur"},
        {productType: "LPT", name: "Ordinateur"},
    ];

    return <div>
        <Message><b>{t('page.fleet.start')}</b></Message>
        <Card.Group itemsPerRow={2} centered={true} textAlign={"center"} stackable={true}>
            {productTypes.map((productType) => (
                <Card onClick={() => handleProductTypeClick(productType.productType)} centered={true}
                      color={"blue"}>
                    <Card.Content>
                        <Card.Header>{t('product.type.' + productType.productType)}</Card.Header>
                    </Card.Content>
                </Card>
            ))}
        </Card.Group>
    </div>
}

export default FleetFirstAdd;