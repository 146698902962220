import {Button, Grid, Icon, Image, Table,} from 'semantic-ui-react'
import React, {useEffect, useState} from "react";
import axiosInstance from "../../utils/axiosInstance";
import {useSelector} from "react-redux";
import mapSave from "./map-branding-save.png";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

function StatShopComponent() {

    const {t} = useTranslation();

    const [shopCount, setShopCount] = useState(0);
    const [shopList, setShopList] = useState([]);
    const broker = useSelector(state => state.user.broker);
    let navigate = useNavigate();

    useEffect(() => {
        axiosInstance.get("/stats/shops", {
            params: {
                contractType: broker.contractType
            }
        })
            .then(res => {
                setShopCount(res.data.totalResult);
                setShopList(res.data.shops);
            })
    }, [broker.code]);

    const today = moment().locale("en").format('dddd').toLowerCase();
    return (<>

            {shopCount > 3 &&
                <div className='shop-card'>
                    <div className='shop-card-title'>
                        <h3><FontAwesomeIcon icon="fa-solid fa-store" /> &nbsp;&nbsp;{t('page.home.service-save')}</h3>
                    </div>
                    <div className='card-info'>
                        <Grid>
                            <Grid.Row centered>
                                <Grid.Column width={6}>
                                    <Image src={mapSave} size='large' style={{marginLeft: '20px'}}
                                           verticalAlign={"middle"}/>
                                </Grid.Column>
                                <Grid.Column width={8} style={{paddingLeft: '40px'}} verticalAlign={"middle"}>
                                    <h3>{t('page.home.leader')}<br/>{t('page.home.country')}</h3>
                                    <Button primary onClick={() => navigate('/shops')}>{t('page.home.see-shops')}</Button>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>
                </div>
            }

            {shopCount === 1 &&
                <div className='shop-card'>
                    <div className='shop-card-title'>
                        <h3><FontAwesomeIcon icon="fa-solid fa-store" /> &nbsp;&nbsp;{shopList[0].name}</h3>
                    </div>
                    <div className='card-info'>
                        <ul>
                            <li>
                                <Icon color={"teal"} name='calendar'/>
                                {shopList[0].openingTable[today] ?
                                    <>Horaires du {moment().format('dddd')} : {shopList[0].openingTable[today]}</>
                                    :
                                    <>{t('page.store.closed')} {moment().format('dddd')}</>
                                }
                            </li>
                            <li>
                                <Icon color={"teal"} name='location arrow'/> {shopList[0].address}
                            </li>
                            <li>
                                <Icon color={"teal"} name='phone'/> {shopList[0].phone}
                            </li>
                        </ul>

                        <Button as='a' href={shopList[0].url} target={"_blank"} primary>
                            {t('page.store.detail')}
                        </Button>

                    </div>
                </div>
            }

            {shopCount > 1 && shopCount < 4 &&
                <div className='shop-card'>
                    <div className='shop-card-title'>
                        <h3><FontAwesomeIcon icon="fa-solid fa-store" /> &nbsp;&nbsp; {t('page.store.partners')}</h3>
                    </div>
                    <div className='card-info'>
                        <Table basic='very' collapsing compact singleLine>
                            <Table.Body style={{fontSize: '16px'}}>
                                {shopList.map((shop, index) => {
                                        if (index < 3) {
                                            return (
                                                <Table.Row>
                                                    <Table.Cell>
                                                        <b>{shop.name}</b><br/>
                                                        {shop.street} {shop.zipCode}
                                                    </Table.Cell>
                                                    <Table.Cell>{shop.phone}</Table.Cell>
                                                    <Table.Cell>
                                                        <Button icon as='a' href={shop.url} target={"_blank"} primary>
                                                            <Icon name="eye"/>
                                                        </Button>
                                                    </Table.Cell>
                                                </Table.Row>
                                            )
                                        }
                                    }
                                )}
                            </Table.Body>
                        </Table>
                    </div>
                </div>
            }
        </>
    );
}


export default StatShopComponent;
