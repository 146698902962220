import React, {useEffect, useState} from "react";
import {Button, Container, Form, Label, Segment} from "semantic-ui-react";
import axiosInstance from "../utils/axiosInstance";
import {useNavigate, useParams} from "react-router-dom";
import {useForm} from "react-hook-form";
import {toastSuccess} from "../reducers/Global/global.actions";
import {useTranslation} from "react-i18next";

const UsersAddOrUpdatePage = () => {

    const {t} = useTranslation();

    const {id} = useParams();
    const [user, setUser] = useState(null);
    const {register, handleSubmit, setValue, getValues} = useForm();
    const [isPasswordStrong, setIsPasswordStrong] = useState(false);
    let navigate = useNavigate();

    const getUser = () => {
        if (id) {
            axiosInstance.get("/users/" + id)
                .then((response) => {
                    setUser(response.data)
                })
        } else {
            setValue("ROLE_BROKER_USER", true)
        }
    }
    useEffect(() => {
        getUser();
    }, []);

    useEffect(() => {
        if (user !== undefined && user !== null) {
            setValue("id", user.id)
            setValue("login", user.login)
            setValue("firstname", user.firstname)
            setValue("lastname", user.lastname)
            setValue("email", user.email)
            setValue("password", user.hashedPassword)
            setValue("ROLE_BROKER_ADMIN", user.roles.includes('ROLE_BROKER_ADMIN'))
            setValue("ROLE_BROKER_USER", true)
            if (user && user.hashedPassword) {
                setIsPasswordStrong(validatePassword(user.hashedPassword));
            }

        }
    }, [user]);

    const onSubmit = data => {

        const roles = [];
        if (data.ROLE_BROKER_ADMIN) {
            roles.push("ROLE_BROKER_ADMIN");
        }
        if (data.ROLE_BROKER_USER) {
            roles.push("ROLE_BROKER_USER");
        }
        data.roles = roles.join(";");

        if (!id) {
            axiosInstance.post("/users", data)
                .then(res => {
                    navigate("/users");
                    toastSuccess(t('page.login.user-create-success'));
                })
        } else {
            axiosInstance.put("/users/" + id, data)
                .then(res => {
                    navigate("/users");
                    toastSuccess(t('page.login.user-modify-success'));
                })
        }
    };

    const onPasswordChange = (event) => {
        const newPassword = event.target.value;
        setIsPasswordStrong(validatePassword(newPassword));
        setValue("password", newPassword);
    };

    function validatePassword(newPassword) {
        if (id && user && user.hashedPassword && newPassword === user.hashedPassword) {
            return true;
        }
        const strongPasswordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{12,})");
        return strongPasswordRegex.test(newPassword);
    }

    return (
        <Container>
            <h2>
                {id ? t('page.account.user-modification') : t('page.account.user-creation')}
            </h2>
            <Segment attached>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Field>

                        <Form.Field required={id} hidden={!id}>
                            <label htmlFor="id">Id</label>
                            <input name="id" disabled={true}
                                   {...register('id', {required: id ? true : false, value: id})}
                                   placeholder="id"/>
                        </Form.Field>

                        <Form.Field required={true}>
                            <label htmlFor="login">{t('generic.login')}</label>
                            <input name="login" disabled={id ? true : false}
                                   {...register('login', {required: true})}
                                   placeholder={t('generic.login')}/>
                        </Form.Field>

                        <Form.Group widths='equal'>
                            <Form.Field required={true}>
                                <label htmlFor="lastname">{t('generic.lastname')}</label>
                                <input name="lastname"
                                       {...register('lastname', {required: true})}
                                       placeholder={t('generic.lastname')}/>
                            </Form.Field>

                            <Form.Field required={true}>
                                <label htmlFor="firstname">{t('generic.firstname')}</label>
                                <input name="firstname"
                                       {...register('firstname', {required: true})}
                                       placeholder={t('generic.firstname')}/>
                            </Form.Field>
                        </Form.Group>

                        <Form.Field required={true}>
                            <label htmlFor="email">{t('generic.email')}</label>
                            <input name="email"
                                   {...register('email', {required: true})}
                                   placeholder={t('generic.email')}/>
                        </Form.Field>

                        <Form.Field>
                            <div className="ui toggle checkbox">
                                <input type="checkbox"
                                       name="hasAdminRole" {...register('ROLE_BROKER_ADMIN', {required: false})}/>
                                <label>ROLE_BROKER_ADMIN</label>
                            </div>
                        </Form.Field>

                        <Form.Field>
                            <div className="ui toggle checkbox">
                                <input type="checkbox" disabled={true} checked={true}
                                       name="hasProximityRole" {...register('ROLE_BROKER_USER', {required: false})}/>
                                <label>ROLE_BROKER_USER</label>
                            </div>
                        </Form.Field>

                        <Form.Field required={true}>
                            <label htmlFor="password">{t('page.login.password')}</label>
                            <input name="password" type={"password"}
                                   {...register('password', {required: id ? false : true})}
                                   onChange={onPasswordChange}
                                   placeholder={t('page.login.password')}/>
                        </Form.Field>
                        {<Label color={"blue"} basic style={{marginTop: '-8px'}}>
                            {t('page.account.password-rules')}
                        </Label>}

                    </Form.Field>
                    <Button onClick={() => navigate("/users")}>{t('generic.cancel')}</Button>
                    <Button primary disabled={!isPasswordStrong} type={"submit"}>{t('generic.validate')}</Button>
                </Form>
            </Segment>

        </Container>
    );
};

export default UsersAddOrUpdatePage
