import React, {useEffect, useState} from "react";
import {Button, Container, Form, Grid, Icon, Input, Label, Menu, Pagination, Popup, Table} from "semantic-ui-react";
import axiosInstance from "../utils/axiosInstance";
import Dropdown from "semantic-ui-react/dist/commonjs/modules/Dropdown";
import FileExportButton from "../components/FileExportButton/FileExportButton";
import {NumericFormat} from "react-number-format";
import {useTranslation} from "react-i18next";

const PricingPage = () => {

    const {t} = useTranslation();

    const [fetchSize, setFetchSize] = useState(25);
    const [sortColumn, setSortColumn] = useState("sparePartReference");
    const [sortDirection, setSortDirection] = useState("descending");
    const [activePage, setActivePage] = useState(0);
    const [nbrPage, setNbrPage] = useState(1);
    const [totalElements, setTotalElements] = useState(0);
    const [pricings, setPricings] = useState([]);
    const [searchFilters, setSearchFilters] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [searchOpenPopupColumn, setSearchOpenPopupColumn] = useState(null);


    const fetchPricings = () => {
        axiosInstance.post("/pricing", {
            searchFilters: searchFilters,
        }, {
            params: {
                page: activePage,
                size: fetchSize,
                sort: sortColumn ? (sortColumn + (sortDirection === 'ascending' ? ',asc' : ',desc')) : null
            }
        })
            .then((response) => {
                setPricings(response.data.content);
                setNbrPage(response.data.totalPages)
                setTotalElements(response.data.totalElements)
            })
    }

    function sort(column) {
        if (sortColumn === column) {
            setSortDirection(sortDirection === 'ascending' ? 'descending' : 'ascending');
        } else {
            setSortColumn(column);
            setSortDirection('ascending');
        }
    }

    useEffect(() => {
        fetchPricings();
    }, [activePage, fetchSize, sortColumn, sortDirection, searchFilters]);

    const handlePaginationChange = (e, pageInfo) => {
        setActivePage(pageInfo.activePage - 1);
    };

    function addFilter(column, value, columnName) {
        setSearchValue(value);
        if (searchFiltersHasColumn(column))
            if (!value)
                setSearchFilters(searchFilters.filter(searchFilter => searchFilter.column !== column));
            else
                setSearchFilters([...searchFilters.filter(searchFilter => searchFilter.column !== column), {
                    column: column,
                    value: value,
                    columnName: columnName
                }]);
        else
            setSearchFilters([...searchFilters, {column: column, value: value, columnName: columnName}]);
    }

    function searchFiltersHasColumn(column) {
        return searchFilters.find(searchFilter => searchFilter.column == column);
    }

    function getSearchFilterValue(column) {
        return searchFiltersHasColumn(column) ? searchFiltersHasColumn(column).value : "";
    }

    const headerCell = (columnId, columnName) => <Table.HeaderCell
        sorted={sortColumn === columnId ? sortDirection : null}
        style={{position: "relative"}}
    >
        <Container textAlign="left">{columnName}</Container>
        <Container
            textAlign="right"
            style={{
                position: "absolute",
                bottom: 0,
                left: "50%",
                transform: "translateX(-50%)",
                fontSize: "10px",
            }}
        >

            {["brand", "modelName"].includes(columnId) &&
                <Popup
                    on='click'
                    onOpen={() => {
                        setSearchValue(getSearchFilterValue(columnId))
                        setSearchOpenPopupColumn(columnId)
                    }}
                    open={searchOpenPopupColumn === columnId}
                    onClose={() => setSearchOpenPopupColumn(null)}
                    trigger={
                        <Icon
                            style={{cursor: 'pointer'}}
                            name={searchFiltersHasColumn(columnId) ? "filter" : "search"}
                            color={searchFiltersHasColumn(columnId) ? "teal" : "black"}
                        />
                    }>
                    <Form>
                        <Form.Field>
                            <label>{columnName}</label>
                            <Input
                                action={<Button type='submit' disabled={!searchValue}
                                                onClick={() => {
                                                    addFilter(columnId, searchValue, columnName)
                                                    setSearchOpenPopupColumn(null)
                                                }}>{t('generic.apply')}</Button>}
                                icon='search'
                                onChange={(e, {value}) => setSearchValue(value)}
                                iconPosition='left'
                                placeholder={t('generic.search-placeholder')}
                                autoFocus
                                defaultValue={searchFiltersHasColumn(columnId) ? searchFiltersHasColumn(columnId).value : ""}
                                value={searchValue}
                            />
                            {searchFiltersHasColumn(columnId) && <a onClick={() => {
                                addFilter(columnId, "", columnName)
                                searchOpenPopupColumn === columnId && setSearchOpenPopupColumn(null)
                            }}>{t('generic.delete')}</a>}
                        </Form.Field>
                    </Form>

                </Popup>}
            <Icon
                style={{cursor: 'pointer'}}
                name={sortColumn === columnId ? (sortDirection === "ascending" ? "sort ascending" : "sort down") : "sort"}
                onClick={() => sort(columnId)}
                color={sortColumn === columnId ? "teal" : "black"}
            />
        </Container>

    </Table.HeaderCell>;

    return (
        <>


            <Menu borderless={true} text>
                <Menu.Menu position='right'>
                    <Menu.Item>
                        <FileExportButton url="/pricing/excel" datas={{searchFilters: searchFilters}}
                                          nameFile="pricing"/>
                    </Menu.Item>
                </Menu.Menu>
            </Menu>

            {searchFilters.length > 0 && <b> {t('generic.filters-applied')} : </b>}
            {searchFilters.map((filter, index) => <Label as='a'>
                {filter.columnName}
                <Label.Detail>{filter.value}</Label.Detail>
                <Icon onClick={() => addFilter(filter.column, "")} name='cancel' style={{marginLeft: '10px'}}/>
            </Label>)
            }

            <Table celled fixed stackable striped>
                <Table.Header>
                    <Table.Row>

                        {headerCell("brand", t('generic.brand'))}
                        {headerCell("modelName", t('generic.model'))}
                        {headerCell("sparePartReference", t('page.pricing.sp-reference'))}
                        {headerCell("category", t('page.pricing.sp-label'))}
                        {headerCell("priceHT", t('page.pricing.price-ht'))}
                        {headerCell("priceTTC", t('page.pricing.price-ttc'))}
                        {headerCell("remiseTTC", t('page.pricing.price-discount'))}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {pricings.map((pricing) => (
                        <Table.Row key={pricing.id}>
                            <Table.Cell>{pricing.brand}</Table.Cell>
                            <Table.Cell>{pricing.modelName}</Table.Cell>
                            <Table.Cell>{pricing.sparePartReference}</Table.Cell>
                            <Table.Cell>{t("category." + pricing.category)}</Table.Cell>
                            <Table.Cell><NumericFormat
                                value={pricing.priceHT}
                                displayType={'text'}
                                fixedDecimalScale={true}
                                decimalScale={2}
                                decimalSeparator=','
                                suffix=' €'
                            /></Table.Cell>
                            <Table.Cell style={{textAlign: 'right'}}>
                                <div>
                                    <NumericFormat
                                        value={pricing.priceTTC}
                                        displayType={"text"}
                                        fixedDecimalScale={true}
                                        decimalScale={2}
                                        decimalSeparator=","
                                        suffix=" €"
                                    />
                                </div>

                            </Table.Cell>
                            <Table.Cell style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <div>
                                    {pricing.discountedAmountTTC || pricing.discountPercentage ? <Label size={"mini"} color={"teal"}><NumericFormat
                                        value={pricing.discountPercentage}
                                        displayType={'text'}
                                        fixedDecimalScale={false}
                                        decimalScale={2}
                                        decimalSeparator=','
                                        suffix=' %'
                                        prefix='- '
                                        allowNegative={false}
                                    /></Label> : null}
                                </div>
                                <div><b><NumericFormat
                                    value={(pricing.discountedAmountTTC || pricing.discountPercentage) ? pricing.discountedAmountTTC : pricing.priceTTC}
                                    displayType={'text'}
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                    decimalSeparator=','
                                    suffix=' €'
                                /></b></div>
                            </Table.Cell>
                        </Table.Row>
                    ))}
                    {pricings.length === 0 && <Table.Row>
                        <Table.Cell colSpan={10}>Aucun prix trouvés.</Table.Cell>
                    </Table.Row>}
                </Table.Body>
            </Table>

            <Grid>
                <Grid.Column floated='left' width={5}>
                    {totalElements > 0 && <div style={{textAlign: 'left'}}>
                        {totalElements}{' '} {t('pagination.results')}
                    </div>}
                </Grid.Column>
                <Grid.Column floated='right' width={5}>
                    <div style={{textAlign: 'right'}}>
                        {t('pagination.display')}{' '}
                        <Dropdown upward floating inline value={fetchSize}
                                  onChange={(e, {name, value}) => setFetchSize(value)} options={[
                            {key: '25', value: 25, text: t('pagination.results-25')},
                            {key: '50', value: 50, text: t('pagination.results-50')},
                            {key: '100', value: 100, text: t('pagination.results-100')}]}/>{' '} {t('pagination.by-page')}
                    </div>
                </Grid.Column>
            </Grid>
            {totalElements > 0 && <Pagination
                activePage={activePage + 1}
                onPageChange={handlePaginationChange}
                totalPages={nbrPage}
            />}

        </>
    );
};

export default PricingPage;
