import {useEffect, useState} from "react";
import useAjax from "./useAjax";

const useModelList = (brandId, productType, search) => {

    const [data, setData] = useState([]);
    const [options, setOptions] = useState([]);

    const generateOptions = (types) => {
        let array = [{key: 0, text: "", value: ""}];
        for (let type of types) {
            array.push({
                key: type.id, text: type.name, value: {
                    id: type.id,
                    name: type.name
                }
            })
        }
        setOptions(array)
    }

    const [models] = useAjax(brandId ? "/model/"+productType+"/brand/" + brandId : null, "get", null);

    useEffect(() => {
        generateOptions(models)
    }, [models]);

    return [data, options, setOptions];
}

export default useModelList;