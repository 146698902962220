
export function isIMEI (s,isAdmin) {
    var etal = /^[0-9]{15}$/;
    if (!etal.test(s))
        return false;
    var sum = 0; var mul = 2; var l = 14;
    for (var i = 0; i < l; i++) {
        var digit = s.substring(l-i-1,l-i);
        var tp = parseInt(digit,10)*mul;
        if (tp >= 10)
            sum += (tp % 10) +1;
        else
            sum += tp;
        if (mul == 1)
            mul++;
        else
            mul--;
    }
    var chk = ((10 - (sum % 10)) % 10);
    if (chk != parseInt(s.substring(14,15),10))
        return false;

    return true;
}

export function isByPassIMEI (s) {
    return (s === '911111111111111');
}
