import React from "react";
import {Checkbox, Container, Form, Header, Icon, Message, Segment} from "semantic-ui-react";
import useAjax from "../hooks/useAjax";
import {NumericFormat} from "react-number-format";
import {useTranslation} from "react-i18next";

const PartnerInfoPage = () => {

    const {t} = useTranslation();

    const [partnerDetails] = useAjax("/partner", "get", null);

    return (
        <Container>
            <Message info>{t('page.partner.warning')}</Message>

            <h2>{t('page.partner.infos')}</h2>
            <Segment attached>
                <Form>
                    <Form.Group widths='equal'>
                        <Form.Field>
                            <label>{t('generic.code')}</label>
                            <Form.Input fluid value={partnerDetails.code ? partnerDetails.code : null} readOnly/>
                        </Form.Field>
                        <Form.Field>
                            <label>{t('generic.name')}</label>
                            <Form.Input fluid value={partnerDetails.name ? partnerDetails.name : null} readOnly/>
                        </Form.Field>
                    </Form.Group>
                </Form>
            </Segment>

            <h2>{t('generic.address')}</h2>
            <Segment attached>
                <Form>
                    <Header as='h6' dividing>
                        <Icon name='mail'/>
                        {t('page.partner.postal-info')}
                    </Header>
                    <Form.Group widths='equal'>
                        <Form.Field>
                            <label>{t('generic.address')}</label>
                            <Form.Input fluid value={partnerDetails.address ? partnerDetails.address : ""} readOnly/>
                        </Form.Field>
                        <Form.Field>
                            <label>{t('generic.address-comp')}</label>
                            <Form.Input fluid value={partnerDetails.addressCompl ? partnerDetails.addressCompl : ""}
                                        readOnly/>
                        </Form.Field>
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Field>
                            <label>{t('generic.postal-code')}</label>
                            <Form.Input fluid value={partnerDetails.zipCode ? partnerDetails.zipCode : ""} readOnly/>
                        </Form.Field>
                        <Form.Field>
                            <label>{t('generic.city')}</label>
                            <Form.Input fluid value={partnerDetails.city ? partnerDetails.city : ""} readOnly/>
                        </Form.Field>
                        <Form.Field>
                            <label>{t('generic.country')}</label>
                            <Form.Input fluid value={partnerDetails.country ? partnerDetails.country : ""} readOnly/>
                        </Form.Field>
                    </Form.Group>

                    <Header as='h6' dividing>
                        <Icon name='compass'/>
                        {t('page.partner.gps')}
                    </Header>
                    <Form.Group widths='equal'>
                        <Form.Field>
                            <label>{t('page.partner.latitude')}</label>
                            <Form.Input fluid value={partnerDetails.latitude ? partnerDetails.latitude : ""} readOnly/>
                        </Form.Field>
                        <Form.Field>
                            <label>{t('page.partner.longitude')}</label>
                            <Form.Input fluid value={partnerDetails.longitude ? partnerDetails.longitude : ""}
                                        readOnly/>
                        </Form.Field>
                    </Form.Group>
                </Form>
            </Segment>

            <h2>{t('page.partner.contact')}</h2>
            <Segment attached>
                <Form>
                    <Form.Group widths='equal'>
                        <Form.Field>
                            <label>{t('page.partner.mail')}</label>
                            <Form.Input fluid value={partnerDetails.mail ? partnerDetails.mail : ""} readOnly/>
                        </Form.Field>
                        <Form.Field>
                            <label>{t('page.partner.manager-mail')}</label>
                            <Form.Input fluid value={partnerDetails.mailGerant ? partnerDetails.mailGerant : ""}
                                        readOnly/>
                        </Form.Field>
                        <Form.Field>
                            <label>{t('generic.phone-number')}</label>
                            <Form.Input fluid value={partnerDetails.phoneNumber ? partnerDetails.phoneNumber : ""}
                                        readOnly/>
                        </Form.Field>
                    </Form.Group>
                </Form>
            </Segment>

            <h2>{t('page.partner.finance')}</h2>
            <Segment attached>
                <Form>
                    <Form.Group widths='equal'>
                        <Form.Field>
                            <label>{t('page.partner.tva')}</label>
                            <Form.Input fluid value={partnerDetails.tvaIntraCom ? partnerDetails.tvaIntraCom : ""}
                                        readOnly/>
                        </Form.Field>
                        <Form.Field>
                            <label>{t('page.partner.tva-default')}</label>
                            {partnerDetails.tvaName ? <>{partnerDetails.tvaName} - <NumericFormat
                                value={partnerDetails.tvaAmount}
                                displayType={'text'}
                                fixedDecimalScale={true}
                                decimalScale={2}
                                decimalSeparator=','
                                suffix=' %'
                            /></> : ""}
                        </Form.Field>
                    </Form.Group>
                </Form>
            </Segment>

            <h2>{t('page.partner.options')}</h2>
            <Segment attached>
                <Header as='h4' dividing>
                    {t('page.partner.bills')}
                </Header>
                <Form>
                    <Form.Group widths='equal'>
                        <Form.Field>
                            <label>{t('page.partner.quote-validation')}</label>
                            <div className="ui toggle checkbox">
                                <Checkbox
                                    checked={partnerDetails.validateDevisRequirement ? true : false}
                                    readOnly={true}
                                    label={partnerDetails.validateDevisRequirement ? t('generic.yes') : t('generic.no')}/>
                            </div>
                        </Form.Field>
                        <Form.Field>
                            <Form.Field>
                                <label>{t('page.partner.relance')}</label>
                                <Form.Input fluid
                                            value={partnerDetails.quoteRevival ? partnerDetails.quoteRevival : ""}
                                            readOnly/>
                            </Form.Field>
                        </Form.Field>
                    </Form.Group>
                </Form>
                <Header as='h4' dividing>
                    {t('page.partner.user-options')}
                </Header>
                <Form>
                    <Form.Group widths='equal'>
                        <Form.Field>
                            <label>{t('page.partner.access-creation')}</label>
                            <div className="ui toggle checkbox">
                                <Checkbox
                                    checked={partnerDetails.multiUser}
                                    readOnly={true}
                                    label={partnerDetails.multiUser ? t('generic.yes') : t('generic.no')}/>
                            </div>
                        </Form.Field>
                        <Form.Field>
                            <Form.Field>
                                <label>{t('page.partner.max-users')}</label>
                                <Form.Input fluid
                                            value={partnerDetails.multiUserLimit ? partnerDetails.multiUserLimit : ""}
                                            readOnly/>
                            </Form.Field>
                        </Form.Field>
                    </Form.Group>
                </Form>
            </Segment>

        </Container>
    );
};

export default PartnerInfoPage;
