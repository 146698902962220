import {Button, Grid, Icon, Message, Segment} from 'semantic-ui-react'
import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import PageHeader from "../PageHeader/PageHeader";
import {clearCreationWorkflow} from "../../reducers/Create/create.actions";
import {useTranslation} from "react-i18next";

function DamageTypeComponent(props) {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const {setStep, setPreviousButtonState, setNextButtonState} = props;
    const [isBent, setIsBent] = React.useState(false);
    const [isOxidized, setIsOxidized] = React.useState(false);

    useEffect(() => {
        setPreviousButtonState({
            disabled: false,
            onPrevious: () => {
                setStep("SELECT_COLOR")
            }
        });
    }   , [])

    useEffect(() => {
        if (isBent || isOxidized) {
            setNextButtonState({
                disabled: false,
                label: t('generic.quit'),
                isPrimary: true,
                onNext: () => {
                    dispatch(clearCreationWorkflow())
                    navigate("/")
                }
            });
        } else {
            setNextButtonState({
                disabled: false,
                label: t('generic.next'),
                isPrimary: true,
                onNext: () => setStep((user.broker.sgCoverBroker || user.broker.contractType === 'INSURANCE') ? "SELECT_GUARANTEE" : "SELECT_REPAIRS")
            });
        }
    }, [isBent, isOxidized])

    return (
        <>

            <PageHeader title={t('page.intervention.product-state')}/>
            <Segment style={{padding: '50px'}}>
            <Grid columns={2} divided>
                <Grid.Row>
                    <Grid.Column textAlign={"center"}>
                        <h5>{t('page.intervention.product-bent')}</h5>
                        <Button.Group>
                            <Button primary={isBent} onClick={() => setIsBent(true)}>{t('generic.yes')}</Button>
                            <Button primary={!isBent} onClick={() => setIsBent(false)}>{t('generic.no')}</Button>
                        </Button.Group>
                    </Grid.Column>
                    <Grid.Column textAlign={"center"}>
                        <h5>{t('page.intervention.product-corroded')}</h5>
                        <Button.Group>
                            <Button primary={isOxidized} onClick={() => setIsOxidized(true)}>{t('generic.yes')}</Button>
                            <Button primary={!isOxidized} onClick={() => setIsOxidized(false)}>{t('generic.no')}</Button>
                        </Button.Group>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            </Segment>

            {(isBent || isOxidized) &&
                <><Message icon error attached>
                    <Icon name='warning'/>
                    <Message.Content>
                        <Message.Header>{t('page.intervention.product-refused-1')}</Message.Header>
                        {t('page.intervention.product-refused-2')}
                    </Message.Content>
                </Message>
                </>
            }
        </>
    );
}


export default DamageTypeComponent;
