import React, {useEffect, useState} from "react";
import {
    Button,
    Checkbox,
    Container,
    Icon,
    Input,
    Label,
    Menu,
    Pagination,
    Popup,
    Table,
    TableBody
} from "semantic-ui-react";
import axiosInstance from "../utils/axiosInstance";
import {useNavigate} from "react-router-dom";
import {toastSuccess} from "../reducers/Global/global.actions";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

const UsersManagementPage = () => {

        const {t} = useTranslation();

        const navigate = useNavigate();
        const loggedUserLogin = useSelector(state => state.user.login);

        const [users, setUsers] = useState([]);
        const [search, setSearch] = useState("");
        const [activePage, setActivePage] = useState(0);
        const [nbrPage, setNbrPage] = useState(1);

        useEffect(() => {
            getAllUsers();
        }, [search, activePage]);

        const getAllUsers = () => {
            axiosInstance.get("/users", {
                params: {
                    page: activePage,
                    size: 15,
                    search: search
                }
            })
                .then((response) => {
                    setUsers(response.data.content);
                    setNbrPage(response.data.totalPages)
                })
        }

        const handlePaginationChange = (e, pageInfo) => {
            setActivePage(pageInfo.activePage - 1);
        };

        function updateUserStatus(user) {
            axiosInstance.post("/users/" + user.id + "/" + (user.enabled ? "disable" : "enable"))
                .then((response) => {
                    getAllUsers();
                    toastSuccess(
                        t('page.login.active-1') + " " +
                        user.login + " " +
                        t('page.login.active-2') + " " +
                        (user.enabled ? t('page.login.active-3') : t('page.login.active-4')) + " " +
                        t('page.login.active-5'));
                })
        }


        return (
            <Container>
                <Menu borderless={true} text>
                    <Menu.Menu position='right'>
                        <Menu.Item>
                            <Button
                                floated='right'
                                labelPosition='left'
                                icon
                                primary
                                size='small'
                                onClick={() => navigate('/users/add')}>
                                <Icon name='add'/> {t('page.titles.UsersAddPage')}
                            </Button>
                        </Menu.Item>
                        <Menu.Menu position='right'>
                            <Menu.Item>
                                <Input icon='search' value={search} placeholder={t('generic.search-placeholder')}
                                       onChange={(e, {name, value}) => setSearch(value)}/>
                            </Menu.Item>
                        </Menu.Menu>
                    </Menu.Menu>
                </Menu>

                <Table celled compact definition>
                    <Table.Header fullWidth>
                        <Table.Row>
                            <Table.HeaderCell>{t('generic.login')}</Table.HeaderCell>
                            <Table.HeaderCell>{t('generic.email')}</Table.HeaderCell>
                            <Table.HeaderCell>{t('generic.firstname')}</Table.HeaderCell>
                            <Table.HeaderCell>{t('generic.lastname')}</Table.HeaderCell>
                            <Table.HeaderCell>{t('generic.roles')}</Table.HeaderCell>
                            <Table.HeaderCell>{t('generic.active')}</Table.HeaderCell>
                            <Table.HeaderCell>{t('generic.actions')}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <TableBody>
                        {users.map((user) =>
                            <Table.Row key={user.id}>
                                <Table.Cell>{user.login}</Table.Cell>
                                <Table.Cell>{user.email}</Table.Cell>
                                <Table.Cell>{user.firstname}</Table.Cell>
                                <Table.Cell>{user.lastname}</Table.Cell>
                                <Table.Cell> {user.roles.split(';').map((value, index) => <Label
                                    key={index}>{t('roles.'+value)}</Label>)}</Table.Cell>
                                <Table.Cell>
                                    <div className="ui toggle checkbox">
                                        <Checkbox
                                            // value={user.enabled}
                                            checked={user.enabled}
                                            disabled={user.login === loggedUserLogin}
                                            onChange={(e, {name, value}) => {
                                                updateUserStatus(user);
                                            }
                                            }
                                        />
                                    </div>
                                </Table.Cell>
                                <Table.Cell>
                                    <Button.Group basic size='small'>
                                        <Popup size='small' content={t('page.account.edit')}
                                               trigger={<Button basic icon onClick={() => navigate('/users/' + user.id)}>
                                                   <Icon name='edit'/>
                                               </Button>}/>
                                    </Button.Group>
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </TableBody>
                </Table>

                <Pagination style={{marginTop: '8px'}}
                            activePage={activePage + 1}
                            onPageChange={handlePaginationChange}
                            totalPages={nbrPage}
                />

            </Container>
        );
    }
;

export default UsersManagementPage;
