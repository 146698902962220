import {Button, Icon} from "semantic-ui-react";
import React from "react";
import {loading} from "../../reducers/Global/global.actions";
import {useDispatch} from "react-redux";
import moment from "moment";
import {useTranslation} from "react-i18next";
import axiosInstance from "../../utils/axiosInstance";

function FileExportButton({url, nameFile, datas}) {
    const {t, i18n} = useTranslation();

    let dispatch = useDispatch();

    const download = () => {
        dispatch(loading("Fichier en construction..."));
        axiosInstance.post(url, datas, {
            responseType: 'blob',
        })
            .then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                let fullFileName = nameFile + '_' + moment().format('YYYYMMDD_HHmmss') + '.xlsx';
                link.setAttribute('download', fullFileName);
                document.body.appendChild(link);
                link.click();
            })
    }

    return (
        <>
            <Button
                floated='right'
                labelPosition='left'
                icon
                primary
                size='small'
                onClick={download}>
                <Icon name='upload'/> Export
            </Button>
        </>
    );
}


export default FileExportButton;
