import HeaderApp from "../components/HeaderApp/HeaderApp";
import {Container, Dimmer, Loader} from "semantic-ui-react";
import React, {useEffect, useState} from "react";
import MenuSideBar from "../components/MenuSideBar/MenuSideBar";
import {useSelector} from "react-redux";


function StandardLayout(props) {

    const dimmerMessage = useSelector(state => state.global.dimmerMessage);
    const [counter, setCounter] = useState(null);
    const [autoHideMenu, setAutoHideMenu] = useState(false);

    const [isSidebarVisible, setSidebarVisible] = useState(true);

    const toggleSidebar = () => {
        setSidebarVisible(!isSidebarVisible);
        setAutoHideMenu(false);
    };

    useEffect(() => {
        setCounter(dimmerMessage ? 0 : -1);
    }, [dimmerMessage]);

    useEffect(() => {
        const timer = counter > -1 && setInterval(() => setCounter(counter + 1), 1000);
        return () => clearInterval(timer);
    }, [counter]);

    useEffect(() => {
        const handleResize = () => {
            if(window.innerWidth > 768) {
                if(autoHideMenu) {
                    setSidebarVisible(true);
                }
            } else {
                setSidebarVisible(false);
                setAutoHideMenu(true);
            }
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [window.innerWidth]);

    return (
        <>
            <div>
                <div className={isSidebarVisible ? "layout_app_with_sidebar" : "layout_app_without_sidebar"}>
                    {isSidebarVisible && <div className="side_app">
                        <MenuSideBar/>
                    </div>}

                    <>
                        <Dimmer active={dimmerMessage ? true : false}>
                            <Loader indeterminate>{dimmerMessage} <br/><br/>{dimmerMessage && <>{counter} secondes</>}
                            </Loader>
                        </Dimmer>
                        <div className="main_app">
                            <HeaderApp toggleSidebar={toggleSidebar} />
                            <Container style={{marginBottom: '30px', marginTop: '40px', padding: '10px'}}>
                                {props.title && <h1>{props.title}</h1>}
                                {props.page}
                            </Container>
                        </div>
                    </>
                </div>
            </div>
        </>
    );

}

export default StandardLayout