import {
    CLEAR_CREATION_WORKFLOW,
    RESET,
    SET_COLOR,
    SET_GUARANTEE_TYPE,
    SET_INTERVENTION_ID,
    SET_PRODUCT,
    SET_PRODUCT_TYPE,
    UPDATE_CONDITIONS,
    UPDATE_CUSTOMER,
    UPDATE_REPAIRS
} from "./create.types";

const INITIAL_STATE = {
    productType: null,
    color: null,
    repairs: [],
    customer: null,
    conditions: null,
    product: null,
    interventionId: null,
    guaranteeType: "UNDEFINED",
    guaranteeComment: "",
    damageDate: null
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case SET_PRODUCT_TYPE: {
            // reset all values only if productType is different from previous one
            if (state.productType !== action.productType) {
                return {
                    ...state,
                    productType: action.productType,
                    color: null,
                    product: null,
                    repairs: [],
                    customer: null,
                    conditions: null,
                    interventionId: null,
                    guaranteeComment: "",
                    damageDate: null
                }
            } else {
                return state;
            }
        }

        case SET_PRODUCT: {
            return {
                ...state,
                product: action.product,
            }
        }

        case SET_COLOR: {
            return {
                ...state,
                color: action.color,
            }
        }

        case SET_GUARANTEE_TYPE: {
            return {
                ...state,
                guaranteeType: action.guaranteeType,
                guaranteeComment: action.guaranteeComment,
                damageDate: action.damageDate
            }
        }

        case SET_INTERVENTION_ID: {
            return {
                ...state,
                interventionId: action.interventionId,
            }
        }

        case UPDATE_REPAIRS: {
            return {
                ...state,
                repairs: action.repairs
            }
        }

        case UPDATE_CUSTOMER: {
            return {
                ...state,
                customer: action.customer
            }
        }

        case UPDATE_CONDITIONS: {
            return {
                ...state,
                conditions: action.conditions
            }
        }

        case CLEAR_CREATION_WORKFLOW: {
            return {
                ...state,
                productType: null,
                color: null,
                product: null,
                repairs: [],
                customer: null,
                conditions: null,
                interventionId: null,
                guaranteeType: "UNDEFINED",
                guaranteeComment: "",
                damageDate: null
            }
        }

        case RESET: {
            return {
                ...state,
                dimmerMessage: "",
            }
        }

        default:
            return state;
    }
};

export default reducer;