import React, {useState} from "react";
import {Grid, Header, Icon, Image, Label, Table} from "semantic-ui-react";
import {NumericFormat} from "react-number-format";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import test from "../../views/test.svg";

function InitialQuoteTable({intervention}) {

    const {t, i18n} = useTranslation();
    const ImageWithFallback = ({src, style}) => {
        const [imgError, setImgError] = useState(false);

        const handleError = () => {
            setImgError(true);
        };

        return imgError ? (<FontAwesomeIcon icon="fa-regular fa-screwdriver-wrench" style={{fontSize: "30px", padding:"5px"}}/>) : (<Image
            spaced='right'
            src={src}
            onError={handleError}
            style={style}
        />);
    };


    let totalQuoteDiscountAmountTTC = intervention.initialRepairs.map((repair) => (repair.discountTTC)).reduce((x, y) => x + y, 0);
    let totalQuoteAmountTTC = intervention.initialRepairs.map((repair) => (repair.amountTTC)).reduce((x, y) => x + y, 0);
    let totalQuoteDiscountedAmountTTC = totalQuoteAmountTTC - totalQuoteDiscountAmountTTC;
    let isSGBroker = intervention && intervention.guaranteeCode === 'COV_BROKER';
    let isInsurance = intervention && !intervention.guaranteeCode;

    if (isSGBroker || isInsurance) {
        return (<span>

            <Grid textAlign={"left"} style={{marginTop: '10px'}}>
            <Grid.Row columns={2} style={{marginLeft: '20px'}}>
                <Grid.Column width={9}>
                    <div style={{
                        border: '1px solid grey',
                        borderRadius: '8px',
                        padding: '15px 20px 15px 20px',
                        height: '100%',
                        minWidth: '250px'
                    }}>
                        <Header as='h4' style={{boxSizing: 'border-box'}}>
                            <FontAwesomeIcon icon="fa-regular fa-screwdriver-wrench" style={{marginRight: '5px'}}/>
                            {t('page.repairs.repairs')}
                        </Header>
                        <Grid style={{paddingBottom: "5px"}}>
                            {intervention.initialRepairs?.map((repair, index) => {
                                return (<Grid.Column key={index} style={{
                                    padding: '5px',
                                    marginTop: "5px",
                                    marginLeft: "10px",
                                    flexGrow: "1",
                                    width: "auto"
                                }}>
                                    <div style={{
                                        margin: '0px !important',
                                        backgroundColor: '#E8E8E8',
                                        borderRadius: '10px',
                                        padding: '5px 10px',
                                        minWidth: '200px',
                                        minHeight: '50px',
                                        display: "inline-block"
                                    }}>
                                        <Grid>
                                            <Grid.Row>
                                                <Grid.Column style={{
                                                    width: "auto", display: "inline-block"
                                                }}>
                                                    <ImageWithFallback
                                                        spaced='right'
                                                        src={"https://drpciyuuf9kdh.cloudfront.net/save/quote/quote-repair/partner/Icon_" + repair.category + ".svg"}
                                                        style={{
                                                            margin: 'auto',
                                                            width: 'auto',
                                                            height: '35px'
                                                        }}
                                                    />
                                                </Grid.Column>
                                                <Grid.Column verticalAlign='middle' style={{
                                                    padding: "0px 5px"
                                                }}>
                                                    <p style={{marginBottom: "0px", marginRight: "10px", whiteSpace: "nowrap"}}><b>{i18n.exists('category.' + repair.category) ? t('category.' + repair.category) : repair.category}</b></p>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </div>
                                </Grid.Column>)
                            })}
                        </Grid>
                    </div>
                </Grid.Column>
                <Grid.Column width={7}>
                    <div style={{
                        border: '1px solid grey',
                        borderRadius: '8px',
                        padding: '15px 20px 15px 20px',
                        height: '100%',
                        minWidth: '250px'
                    }}>
                        <Header as='h4'>
                            <FontAwesomeIcon icon="fa-regular fa-comment" style={{marginRight: '5px'}}/>
                            {t('page.repairs.customer-comment')}
                        </Header>
                        <p style={{color: 'grey', textAlign: 'center'}}>"{intervention.guaranteeComment}"</p>
                    </div>
                </Grid.Column>
                </Grid.Row>

            </Grid>
        </span>);
    }

    const PriceTable = () =>
    {
        const table = <Table basic={"very"} compact={true} style={{padding: '5px 20px 5px 20px'}}>
            <Table.Body>
                <Table.Row>
                    <Table.HeaderCell>{t('page.repairs.repairs')}</Table.HeaderCell>
                    <Table.HeaderCell textAlign={"right"}>{t('page.pricing.price-ttc')}</Table.HeaderCell>
                </Table.Row>
                {intervention.initialRepairs?.map((repair, index) => {
                    return (<Table.Row style={{padding: '0px !important'}} key={index}>
                        <Table.Cell style={{margin: '0px !important'}}>
                            {i18n.exists('category.' + repair.category) ? t('category.' + repair.category) : repair.category}
                        </Table.Cell>
                        <Table.Cell textAlign={"right"}>
                            <NumericFormat
                                value={repair.amountTTC}
                                displayType={'text'}
                                fixedDecimalScale={true}
                                decimalScale={2}
                                decimalSeparator=','
                                suffix=' €'
                            />
                        </Table.Cell>
                    </Table.Row>)
                })}

                {totalQuoteDiscountAmountTTC && <>
                    <Table.Row style={{padding: '0px !important'}}>
                        <Table.Cell style={{margin: '0px !important'}}>
                            <Label size={"tiny"} basic color={"teal"}>{t('page.summary.partner-discount')}</Label>
                        </Table.Cell>
                        <Table.Cell textAlign={"right"}>
                            <NumericFormat
                                value={totalQuoteDiscountAmountTTC}
                                displayType={'text'}
                                fixedDecimalScale={true}
                                decimalScale={2}
                                decimalSeparator=','
                                suffix=' €'
                                prefix=' -'
                                allowNegative={false}
                            />
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row style={{padding: '0px !important'}}>
                        <Table.Cell style={{margin: '0px !important'}}>
                            <b>{t('page.summary.total')}</b>
                        </Table.Cell>
                        <Table.Cell textAlign={"right"}>
                            <b><NumericFormat
                                value={totalQuoteDiscountedAmountTTC}
                                displayType={'text'}
                                fixedDecimalScale={true}
                                decimalScale={2}
                                decimalSeparator=','
                                suffix=' €'
                            /></b>
                        </Table.Cell>
                    </Table.Row>
                </>}
            </Table.Body>
        </Table>;

        return (
            <>
                {intervention.guaranteeComment!==null ? <Grid textAlign={"left"} style={{marginTop: '10px'}}>
                    <Grid.Row columns={2}>
                        <Grid.Column width={9}>
                            {table}
                        </Grid.Column>
                        <Grid.Column width={7}>
                            <div style={{
                                border: '1px solid grey',
                                borderRadius: '8px',
                                padding: '15px 20px 15px 20px',
                                height: '100%',
                                minWidth: '250px'
                            }}>
                                <Header as='h4'>
                                    <FontAwesomeIcon icon="fa-regular fa-comment" style={{marginRight: '5px'}}/>
                                    {t('page.repairs.customer-comment')}
                                </Header>
                                <p style={{color: 'grey', textAlign: 'center'}}>"{intervention.guaranteeComment}"</p>
                            </div>
                        </Grid.Column>
                    </Grid.Row>

                </Grid> : (table)}
            </>
        )
    }

    return (
        <PriceTable/>
    )

}


export default InitialQuoteTable;
