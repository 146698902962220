import React, {useEffect, useState} from "react"
import {Icon, Popup, Table, Button, Checkbox} from 'semantic-ui-react'
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import axiosInstance from "../../utils/axiosInstance";
import {getAllFleets} from "./FleetService";

function TableFleet(prop) {
    let navigate = useNavigate();
    const {fleets, setFleets} = useState(prop);
    const {t} = useTranslation();

    const menuChange = (path) => {
        navigate(path);
    }

    const deleteFleet = (fleet) => {
        axiosInstance.delete("/fleets/" + fleet.fleetId)
            .then((response) => {
                getAllFleets(setFleets);
            })
            .catch((e) => {
            });
    }

    return <div>
        <Table celled compact definition selectable color={"white"}>
            <Table.Header fullWidth>
                <Table.Row>
                    <Table.HeaderCell/>
                    <Table.HeaderCell>Nom</Table.HeaderCell>
                    <Table.HeaderCell>Type de produit</Table.HeaderCell>
                    <Table.HeaderCell>Date de création</Table.HeaderCell>
                    <Table.HeaderCell>Créer par</Table.HeaderCell>
                    <Table.HeaderCell>Modifier par</Table.HeaderCell>
                    <Table.HeaderCell>Date de modification</Table.HeaderCell>
                    <Table.HeaderCell>Active</Table.HeaderCell>
                    <Table.HeaderCell>Actions</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {/*{fleets && fleets.map(fleet =>*/}
                {prop.fleets && prop.fleets.map(fleet =>
                    <Table.Row key={fleet.id} style={{cursor: 'pointer'}}>
                        <Table.Cell
                            onClick={() => menuChange('/fleets/'+fleet.fleetId+'/products')}> {fleet.fleetId}</Table.Cell>
                        <Table.Cell
                            onClick={() => menuChange('/fleets/'+fleet.fleetId+'/products')}> {fleet.name} </Table.Cell>
                        <Table.Cell
                            onClick={() => menuChange('/fleets/'+fleet.fleetId+'/products')}> {t('product.type.' + fleet.productType)}</Table.Cell>
                        <Table.Cell
                            onClick={() => menuChange('/fleets/'+fleet.fleetId+'/products')}> {fleet.creationDate}</Table.Cell>
                        <Table.Cell
                            onClick={() => menuChange('/fleets/'+fleet.fleetId+'/products')}> {fleet.userCreatorLogin}</Table.Cell>
                        <Table.Cell
                            onClick={() => menuChange('/fleets/'+fleet.fleetId+'/products')}> {fleet.lastUpdatedUserLogin}</Table.Cell>
                        <Table.Cell
                            onClick={() => menuChange('/fleets/'+fleet.fleetId+'/products')}> {fleet.lastUpdateDate}</Table.Cell>
                        <Table.Cell onClick={() => menuChange('/fleets/'+fleet.fleetId+'products')}>
                            <div className="ui toggle checkbox">
                                <Checkbox
                                    value={fleet.enabled}
                                    checked={fleet.enabled}/>
                            </div>
                        </Table.Cell>
                        <Table.Cell>
                            <Button.Group basic size='small'>
                                <Popup size='small' content={"Ajouter un produit"}
                                       trigger={<Button basic icon
                                                        onClick={() => menuChange('/fleets/'+fleet.fleetId+'/products/add')}>
                                           <Icon name='add'/>
                                       </Button>}/>

                                <Popup size='small' content={"Editer la flotte"}
                                       trigger={<Button basic icon
                                                        onClick={() => menuChange('/fleets/' + fleet.fleetId +'/update')}>
                                           <Icon name='edit'/>
                                       </Button>}/>
                                <Popup size='small' content={t('page.fleet.delete')}
                                       trigger={<Button basic icon
                                                        onClick={() =>deleteFleet(fleet)}>
                                           <Icon color={'red'} name='delete'/>
                                       </Button>}/>
                            </Button.Group>
                        </Table.Cell>
                    </Table.Row>
                )}
            </Table.Body>
        </Table>
    </div>
}

export default TableFleet;