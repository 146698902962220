import {Button, Grid, Header, Popup,} from 'semantic-ui-react'
import React from "react";
import {
    getMonthLabel,
    getYearLabel,
    rangeDateOfFullMonth,
    rangeDateOfFullYear,
    rangeDateOfLastXDays
} from "../../utils/date-util";
import {useTranslation} from "react-i18next";

function FastRangeDateSelection(props) {

    const {t} = useTranslation();

    const {trigger, setRangeDate} = props;

    return (
        <>
            <Popup trigger={trigger} flowing hoverable>
                <Grid centered divided columns={3}>
                    <Grid.Column textAlign='center' style={{minWidth: '150px'}}>
                        <Header as='h4'>{t('filters.days')}</Header>
                        <Button onClick={() => setRangeDate(rangeDateOfLastXDays(30))} compact fluid basic
                                size={"mini"}>{t('filters.30-days')}</Button><br/>
                        <Button onClick={() => setRangeDate(rangeDateOfLastXDays(90))} compact fluid basic
                                size={"mini"}>{t('filters.90-days')}</Button><br/>
                        <Button onClick={() => setRangeDate(rangeDateOfLastXDays(120))} compact fluid basic
                                size={"mini"}>{t('filters.120-days')}</Button>
                    </Grid.Column>
                    <Grid.Column textAlign='center' style={{minWidth: '150px'}}>
                        <Header as='h4'>{t('filters.month')}</Header>
                        <Button onClick={() => setRangeDate(rangeDateOfFullMonth(0))} compact fluid basic
                                size={"mini"}>{getMonthLabel(0)}</Button><br/>
                        <Button onClick={() => setRangeDate(rangeDateOfFullMonth(-1))} compact fluid basic
                                size={"mini"}>{getMonthLabel(-1)}</Button><br/>
                        <Button onClick={() => setRangeDate(rangeDateOfFullMonth(-2))} compact fluid basic
                                size={"mini"}>{getMonthLabel(-2)}</Button>
                    </Grid.Column>
                    <Grid.Column textAlign='center' style={{minWidth: '150px'}}>
                        <Header as='h4'>{t('filters.year')}</Header>
                        <Button onClick={() => setRangeDate(rangeDateOfFullYear(0))} compact fluid basic
                                size={"mini"}>{getYearLabel(0)}</Button><br/>
                        <Button onClick={() => setRangeDate(rangeDateOfFullYear(-1))} compact fluid basic
                                size={"mini"}>{getYearLabel(-1)}</Button><br/>
                        <Button onClick={() => setRangeDate(rangeDateOfFullYear(-2))} compact fluid basic
                                size={"mini"}>{getYearLabel(-2)}</Button>
                    </Grid.Column>
                </Grid>
            </Popup>
        </>
    );
}


export default FastRangeDateSelection;
