import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import StandardLayout from "./layouts/StandardLayout";
import CreateInterventionPage from "./views/CreateInterventionPage";
import HomePage from "./views/HomePage";
import axiosInstance from "./utils/axiosInstance";
import React, {Suspense, useCallback, useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import LoginPage from "./views/LoginPage";
import AccountManagementPage from "./views/AccountManagementPage";
import 'react-toastify/dist/ReactToastify.css';
import {toast, ToastContainer} from "react-toastify";
import {loading, toastSuccess} from "./reducers/Global/global.actions";
import FleetPage from "./views/fleet/FleetPage";
import FleetAdd from "./views/fleet/FleetAdd";
import FleetForm from "./views/fleet/FleetForm";
import FleetUpdate from "./views/fleet/FleetUpdate";
import {LOGIN_ERROR, LOGOUT, REFRESH_TOKEN} from "./reducers/User/user.types";
import UsersManagementPage from "./views/UsersManagementPage";
import UsersAddOrUpdatePage from "./views/UsersAddOrUpdatePage";
import PartnerInfoPage from "./views/PartnerInfoPage";
import FollowupPage from "./views/FollowupPage";
import ProductForm from "./views/fleet/ProductForm";
import FleetProductPage from "./views/fleet/FleetProductPage";
import DetailInterventionPage from "./views/DetailInterventionPage";
import PricingPage from "./views/PricingPage";
import 'moment/locale/fr';
import moment from "moment/moment";
import FleetProductUpdate from "./views/fleet/FleetProductUpdate";
import {far} from "@fortawesome/pro-regular-svg-icons";
import {fas} from "@fortawesome/pro-solid-svg-icons";
import {library} from '@fortawesome/fontawesome-svg-core'
import ShopListPage from "./views/ShopListPage";
import ForgetPasswordPage from "./views/ForgetPasswordPage";
import {BrandCacheProvider} from "./hooks/useBrandList";
import {getTenant} from "./utils/authorities-utils";
import {useTranslation} from "react-i18next";

function App() {

    const {t} = useTranslation();
    const user = useSelector(state => state.user);
    const dispatch = useDispatch();
    const intervalRef = useRef();
    const [lastAxiosUtilCall, setLastAxiosUtilCall] = React.useState(moment());

    let routes = null;
    library.add(far);
    library.add(fas);

    const getToken = useCallback(
        () => {
            // si la dernière requête axios a eu lieu il y a moins de 45 minutes
            if(moment(lastAxiosUtilCall).isAfter(moment().add(-45, 'minutes'))) {
                // si le token expire dans moins d'une heure, on le rafraichit
                if (user.expirationTokenDate && moment().isAfter(moment(user.expirationTokenDate).add(-1, 'hours'))) {
                    axiosInstance.get("/refresh-token").then(response => {
                        dispatch({type: REFRESH_TOKEN, payload: response.data});
                        toastSuccess(t('page.login.session-add')+" : " + moment(response.data.expirationDate).format('YYYY-MM-DD HH:mm:ss'));
                    });
                }
            }
        },
        [user.expirationTokenDate, lastAxiosUtilCall]
    );

    useEffect(() => {
        const interval = setInterval(() => getToken(), 120000);
        intervalRef.current = interval;
        return () => clearInterval(interval);
    }, [getToken]);

    useEffect(() => {

        const responseinterceptor = axiosInstance.interceptors.response.use(function (response) {
            dispatch(loading(""));
            if(user.isLogged) {
                if (response.config.url !== "/refresh-token") {
                    setLastAxiosUtilCall(moment());
                }
            }
            return response;
        }, function (error) {
            axiosErrorManagement(error);
            return Promise.reject(error);
        });

        return () => {
            axiosInstance.interceptors.response.eject(responseinterceptor);
        };
    }, [user.isLogged]);


    useEffect(() => {
        if(user.expirationTokenDate && moment().isAfter(moment(user.expirationTokenDate))) {
            dispatch({type: LOGOUT});
            dispatch({
                type: LOGIN_ERROR,
                loginErrorMessage: t('page.login.session-expired')
            });
        }
    }, []);

    function axiosErrorManagement(error) {
        if (error && error.response && error.response.data.httpCode === 401 && error.response.data.errorCode === 40101) {
            dispatch({type: LOGOUT});
            dispatch({
                type: LOGIN_ERROR,
                loginErrorMessage: t('page.login.session-expired')
            });
        } else if (error && error.response && error.response.data.httpCode === 403 && error.response.data.errorCode === 40102) {
            dispatch({type: LOGOUT});
            dispatch({
                type: LOGIN_ERROR,
                loginErrorMessage: t('page.login.account-disable')
            });
        } else if (error && error.message === 'Network Error') {
            dispatch({
                type: LOGIN_ERROR,
                loginErrorMessage: t('page.login.network-error')
            });
        } else {
            let isApidaiFunctionalError = error && error.response && error.response.data.httpCode === 400;
            dispatch(loading(""));
            toast.error(isApidaiFunctionalError ? error.response.data.errorMessage : error.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    if (!user.isLogged) {
        routes =
            <Routes>
                <Route path="/reset" exact element={<ForgetPasswordPage/>}/>
                <Route path="/*" element={<LoginPage/>}/>
            </Routes>;
    } else {
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;
        axiosInstance.defaults.headers.common['X-TenantID'] = getTenant();

        routes = <Routes>
            <Route path="/" exact element={<StandardLayout page={<HomePage/>} title={t('page.titles.HomePage')}/>}/>
            <Route path="/create" exact element={<StandardLayout page={<CreateInterventionPage/>} title={t('page.titles.CreateInterventionPage')}/>}/>
            <Route path="/create/:fleetProductId" exact element={<StandardLayout page={<CreateInterventionPage/>} title={t('page.titles.CreateInterventionPage')}/>}/>

            <Route path="/followup" exact element={<StandardLayout page={<FollowupPage/>} title={t('page.titles.FollowupPage')}/>}/>
            <Route path="/followup/:id" exact element={<StandardLayout page={<DetailInterventionPage/>}/>}/>

            <Route path="/pricing" exact element={<StandardLayout page={<PricingPage/>} title={t('page.titles.PricingPage')}/>}/>

            <Route path="/fleets" exact element={<StandardLayout page={<FleetPage/>} title={t('page.titles.FleetPage')}/>}/>
            <Route path="/fleets/:fleetId/products/add" exact element={<ProductForm/>}/>
            <Route path="/fleets/:fleetId/products/:fleetProductId/update" exact element={<FleetProductUpdate/>}/>
            <Route path="/fleets/:fleetId/products" exact element={<FleetProductPage/>}/>
            <Route path="/fleets/add" exact element={<StandardLayout page={<FleetAdd/>} title={t('page.titles.FleetAdd')}/>}/>
            <Route path="/fleets/add/productType/:productTypeCode" exact element={<StandardLayout page={<FleetForm/>}  title={t('page.titles.FleetAdd')}/>}/>
            <Route path="/fleets/:id/update" exact element={<StandardLayout page={<FleetUpdate/>}/>}/>

            <Route path="/account" exact element={<StandardLayout page={<AccountManagementPage/>} title={t('page.titles.AccountManagementPage')}/>}/>
            <Route path="/users" exact element={<StandardLayout page={<UsersManagementPage/>} title={t('page.titles.UsersManagementPage')}/>}/>
            <Route path="/users/:id" exact element={<StandardLayout page={<UsersAddOrUpdatePage/>} title={t('page.titles.UsersUpdatePage')}/>}/>
            <Route path="/users/add" exact element={<StandardLayout page={<UsersAddOrUpdatePage/>} title={t('page.titles.UsersAddPage')}/>}/>
            <Route path="/partner" exact element={<StandardLayout page={<PartnerInfoPage/>} title={t('page.titles.PartnerInfoPage')}/>}/>

            <Route path="/shops" exact element={<StandardLayout page={<ShopListPage/>} title={t('page.titles.ShopListPage')}/>}/>
        </Routes>
    }

    return (
        <BrowserRouter>
            <ToastContainer/>
            {routes}
        </BrowserRouter>
    );
}

// here app catches the suspense from page in case translations are not yet loaded
export default function WrappedApp() {
    return (
        <Suspense fallback="...is loading">
            <BrandCacheProvider>
            <App/>
            </BrandCacheProvider>
        </Suspense>
    );
}

