import React, {useEffect, useState} from "react";
import {Button, Container, Form, Pagination, Table} from "semantic-ui-react";
import {useSelector} from "react-redux";
import axiosInstance from "../utils/axiosInstance";
import {NumericFormat} from "react-number-format";
import {useTranslation} from "react-i18next";

const ShopListPage = () => {
    const user = useSelector(state => state.user);

    const {t} = useTranslation();

    const [shops, setShops] = useState([]);

    const [activePage, setActivePage] = useState(0);
    const [nbrPage, setNbrPage] = useState(1);
    const [totalElements, setTotalElements] = useState(0);
    const [zipCodeForm, setZipCodeForm] = useState("");
    const [zipCodeValid, setZipCodeValid] = useState(false);

    const handlePaginationChange = (e, pageInfo) => {
        setActivePage(pageInfo.activePage - 1);
    };

    function fetchShops() {
        axiosInstance.get("/shops", {
            params: {
                zipCode: zipCodeForm,
                page: activePage,
                size: 10
            }
        }).then(response => {
            if (response.data) {
                setShops(response.data.content);
                setNbrPage(response.data.totalPages)
                setTotalElements(response.data.totalElements)
            }
        }).catch(function (error) {
            setShops([]);
        });
    }

    useEffect(() => {
       fetchShops();
    }, [activePage])

    const handleChange = (e, {name, value}) => {
        setZipCodeForm(value);
        setZipCodeValid(isFrenchZipcode(value))
    }

    const handleSubmit = () => {
        fetchShops();
    }

    function isFrenchZipcode(zipCode) {
        var regex = /^\d{5}$/;
        return regex.test(zipCode);
    }

    return (
        <Container>
            <div style={{float: 'right'}}>
                <Form onSubmit={handleSubmit}>
                    <Form.Group>
                        <Form.Input
                            placeholder={t('generic.postal-code')}
                            name='zipCode'
                            defaultValue={zipCodeForm}
                            onChange={handleChange}
                        />
                        <Form.Button content={t('generic.search')} disabled={!zipCodeValid}/>
                    </Form.Group>
                </Form>
            </div>
            <Table striped>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>{t('generic.name')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('generic.address')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('generic.postal-code')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('generic.city')}</Table.HeaderCell>
                        {shops && shops[0] && shops[0].distance && <Table.HeaderCell>{t('generic.city')}</Table.HeaderCell>}
                        <Table.HeaderCell>{t('generic.actions')}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {shops && shops.map((shop) => (
                        <Table.Row>
                            <Table.Cell>{shop.name}</Table.Cell>
                            <Table.Cell>{shop.street}</Table.Cell>
                            <Table.Cell>{shop.zipCode}</Table.Cell>
                            <Table.Cell>{shop.city}</Table.Cell>
                            {shop.distance && <Table.Cell><NumericFormat
                                value={shop.distance}
                                displayType={'text'}
                                fixedDecimalScale={true}
                                decimalScale={1}
                                decimalSeparator=','
                                suffix=' km'
                            /></Table.Cell>}
                            <Table.Cell>
                                <Button as='a' href={shop.url} target={"_blank"} basic size={"mini"} primary>
                                    {t('generic.website')}
                                </Button>
                            </Table.Cell>
                        </Table.Row>
                    ))}
                    {shops && shops.length === 0 && (
                        <Table.Row>
                            <Table.Cell colSpan={5}>Aucune boutique disponible</Table.Cell>
                        </Table.Row>
                    )}
                </Table.Body>
            </Table>

            <Pagination style={{marginTop: '8px'}}
                        activePage={activePage + 1}
                        onPageChange={handlePaginationChange}
                        totalPages={nbrPage}
                        defaultActivePage={0}
            />

        </Container>
    );
};

export default ShopListPage;
