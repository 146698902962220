import {LOGIN_ERROR, LOGIN_SUCCESS, LOGOUT} from "./user.types";
import axiosLoginInstance from "../../utils/axiosLoginInstance";

export function logUser(logInfo) {
    return async function (dispatch) {

        axiosLoginInstance.post(process.env.REACT_APP_BACKOFFICE_URL + "/partners/v1/signin", {
            login: logInfo.login,
            password: logInfo.password
        }, {headers: {'X-TenantID': logInfo.tenant}})
            .then((response) => {
                dispatch({type: LOGIN_SUCCESS, payload: response.data, tenant: logInfo.tenant});
            })
            .catch((error) => {
                let errorMessage = "";
                if (error.response && error.response.status === 401) {
                    errorMessage = "401 => Mauvais identifiant et/ou mot de passe"
                } else if (error.response && error.response.status === 403) {
                    errorMessage = "403 => Vous n'êtes pas autorisé à utiliser l'outil"
                } else if (error.response) {
                    errorMessage = error.response.status + " => " + error.response.data
                } else {
                    errorMessage = error.message
                }

                dispatch({
                    type: LOGIN_ERROR,
                    loginErrorMessage: errorMessage
                });
            });
    }
}

export function logout() {
    return async function (dispatch) {
        dispatch({type: LOGOUT});
    }
}