import {Button, Form, Grid, Message, Segment, TextArea} from 'semantic-ui-react'
import React, {useEffect} from "react";
import {updateGuaranteeType} from "../../reducers/Create/create.actions";
import {useDispatch, useSelector} from "react-redux";
import PageHeader from "../PageHeader/PageHeader";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import {useTranslation} from "react-i18next";

function SelectGuaranteeComponent(props) {

    const {t} = useTranslation();

    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const {setStep, setPreviousButtonState, setNextButtonState} = props;
    const createIntervention = useSelector(state => state.create);
    const [underGuarantee, setUnderGuarantee] = React.useState(createIntervention.guaranteeType === 'COV_BROKER' ? true : false);
    const [underGuaranteeComment, setUnderGuaranteeComment] = React.useState(createIntervention.guaranteeComment ? createIntervention.guaranteeComment : "");
    const [damageDate, setDamageDate] = React.useState(createIntervention.damageDate ? createIntervention.damageDate : new Date());

    let isSgCoverBrokerOnly = user.broker.sgCoverBroker && !user.broker.hgBroker;
    let isInsurance = user.broker.contractType === 'INSURANCE';

    useEffect(() => {
        setPreviousButtonState({
            disabled: false,
            onPrevious: () => {
                setStep("CHECK_DAMAGE")
            }
        });
    }, [])

    useEffect(() => {
        setNextButtonState({
            disabled: (underGuarantee && !underGuaranteeComment) || (isInsurance && !damageDate) || (isInsurance && !underGuaranteeComment),
            label: t('generic.next'),
            isPrimary: true,
            onNext: () => handleGuaranteeClick()
        });
    }, [underGuarantee, underGuaranteeComment, damageDate])

    function handleGuaranteeClick() {
        if(isInsurance) {
            dispatch(updateGuaranteeType("INSURANCE", underGuaranteeComment, damageDate));
        } else {
            dispatch(updateGuaranteeType((isSgCoverBrokerOnly || underGuarantee) ? "COV_BROKER" : "NGU", underGuaranteeComment));
        }
        setStep("SELECT_REPAIRS");
    }

    const onChange = (event, data) => {
        setDamageDate(data.value);
    }

    return (
        <>
            <PageHeader title={isSgCoverBrokerOnly || isInsurance ? t('page.warranty.details') : t('page.warranty.label-1') }/>
            <Grid columns={1} divided>
                <Grid.Row>
                    <Grid.Column>

                        <Message info>
                            <p>
                                {t('page.warranty.label-2')}
                                <b> {t('page.warranty.label-3')}</b>
                                {t('page.warranty.label-4')}
                            </p>
                        </Message>

                        {!isSgCoverBrokerOnly && !isInsurance ? <Segment textAlign={"center"}>
                            <h5>{t('page.warranty.label-5')}</h5>
                            <Button.Group>
                                <Button primary={underGuarantee} onClick={() => setUnderGuarantee(true)}>{t('generic.yes')}</Button>
                                <Button primary={!underGuarantee} onClick={() => setUnderGuarantee(false)}>{t('generic.no')}</Button>
                            </Button.Group>
                        </Segment> : null}

                        {(isSgCoverBrokerOnly || underGuarantee || isInsurance) && <div style={{marginTop: '25px'}}><Form>
                            <Form.Field
                                control={TextArea}
                                label={t('page.warranty.label-6')}
                                placeholder={t('page.warranty.label-7')}
                                value={underGuaranteeComment}
                                onChange={(e) => setUnderGuaranteeComment(e.target.value)}
                            />

                            { isInsurance && <SemanticDatepicker label={t('page.warranty.incident-date')} showToday={true}
                                                                 value={damageDate} onChange={onChange} type="basic"
                                                                 format={'DD/MM/YYYY'} locale={"fr-FR"}/>}
                        </Form></div>}
                    </Grid.Column>
                </Grid.Row>
            </Grid>

        </>
    );
}


export default SelectGuaranteeComponent;
