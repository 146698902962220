import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {clearCreationWorkflow} from "../../reducers/Create/create.actions";
import {useTranslation} from "react-i18next";
import axiosInstance from "../../utils/axiosInstance";
import {toastSuccess} from "../../reducers/Global/global.actions";
import {useNavigate} from "react-router-dom";
import PageHeader from "../PageHeader/PageHeader";
import SelectShoptable from "./SelectShopTable";

function SelectShopComponent(props) {

    const dispatch = useDispatch();
    const createIntervention = useSelector(state => state.create);
    const {setStep, setPreviousButtonState, setNextButtonState} = props;
    const {t} = useTranslation();
    const [shops, setShops] = useState([]);
    let navigate = useNavigate();

    useEffect(() => {
        axiosInstance.get("/shops", {
            params: {
                zipCode: createIntervention.customer.zipCode
            }
        }).then(response => {
            if (response.data) {
                setShops(response.data.content);
            }
        });
    }, [])

    useEffect(() => {
        setPreviousButtonState({
            disabled: false,
            onPrevious: () => {
                setStep("FINAL_VALID")
            }
        });
        setNextButtonState({
            disabled: false,
            label: t('generic.quit'),
            isPrimary: true,
            onNext: () => {
                dispatch(clearCreationWorkflow())
                navigate("/")
            }
        });
    }, [])

    function updateShop(shop) {
        axiosInstance.post("/intervention/update-shop", {
            shopId: shop.shopId,
            interventionId: createIntervention.interventionId
        }).then(response => {
            dispatch(clearCreationWorkflow())
            toastSuccess("Boutique sélectionnée avec succès");
            navigate("/followup/" + createIntervention.interventionId);
        });
    }

    return (
        <>
            <PageHeader title={"Sélectionnez la boutique dans laquelle compte se rendre le client"}/>

            <SelectShoptable
                interventionId={createIntervention.interventionId}
                zipCode={createIntervention.customer.zipCode}
                onSuccess={(shop) => {
                    updateShop(shop);
                }}
            />
        </>
    );
}

export default SelectShopComponent;
