import React from "react";

function PageHeader(props) {

    return (
        <h2 style={{marginBottom: '20px'}}>{props.title}</h2>
    );
}


export default PageHeader;