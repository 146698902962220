import {Button, Container, Form, Grid, Header, Icon, Image, Message, Segment} from 'semantic-ui-react'
import {logUser} from '../reducers/User/user.actions';
import React, {useEffect, useState} from 'react';

import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import logo from "../assets/LogoSaveBleu.png";
import {getTenant} from "../utils/authorities-utils";
import i18n from "i18next";

function LoginPage(props) {
    const {t} = useTranslation();

    const [login, setLogin] = useState("");
    const [tenant, setTenant] = useState(getTenant());
    const [password, setPassword] = useState("");
    let navigate = useNavigate();
    const user = useSelector(state => state.user);
    const dispatch = useDispatch();


    useEffect(() => {
        if (user.isLogged) {
            navigate("/");
        }
    }, [user.isLogged]);


    const logAction = () => {
        dispatch(logUser({login: login, password: password, tenant: tenant}))
    }

    return (
        <Grid textAlign='center' style={{height: '100vh'}} verticalAlign='middle'>
            <Grid.Column className="login-card" style={{width: 650, background: 'white', paddingTop: '40px', paddingBottom: '40px', paddingLeft: '80px',paddingRight: '80px',verticalAlign: "middle"}} textAlign={"center"}>

                <Image onClick={() => navigate("/")} src={logo}  centered/>

                <span style={{fontSize: '20px', fontWeight: '900', paddingBottom: '28px !important', lineHeight: '30px'}}>
                    {t("page.login.connect")}
                </span>

                <span style={{marginTop:'60px'}}><br/>{t("page.login.usage")}</span>

                <Container style={{maxWidth:'300px !important'}}>
                <Form size='large' style={{marginTop:'40px', marginBottom:'50px'}}>

                    <Form.Input fluid icon='user' iconPosition='left' placeholder={t("page.login.username")}
                                value={login}
                                onChange={(e, {name, value}) => setLogin(value)}/>
                    <Form.Input
                        fluid
                        icon='lock'
                        value={password}
                        iconPosition='left'
                        placeholder={t("page.login.password")}
                        type='password' onChange={(e, {name, value}) => setPassword(value)}
                    />
                    <a style={{float: "right", marginTop:'-12px'}} onClick={() => navigate("/reset")}>{t("page.login.forget")}</a>

                </Form>
                <Button disabled={user.isLoading} loading={user.isLoading} color='teal' fluid size='large'
                        onClick={logAction}>{t("page.login.login")}</Button>
                <br/>
                {user.loginErrorMessage ?
                    <Message warning><Icon name='warning'/> {user.loginErrorMessage}</Message> : null}
                </Container>
            </Grid.Column>
        </Grid>
    );

}

export default LoginPage
