import {Button, Icon, Modal, Segment} from "semantic-ui-react";
import React, {useEffect, useState} from "react";
import {loading} from "../../reducers/Global/global.actions";
import {useDispatch} from "react-redux";
import {toast} from "react-toastify";
import axiosInstance from "../../utils/axiosInstance";
import {useTranslation} from "react-i18next";

function FileImportButton({url, onSuccess}) {

    const {t} = useTranslation();
    
    const [selectedFile, setSelectedFile] = useState(null);
    const [openModal, setOpenModal] = useState(false);

    let dispatch = useDispatch();

    useEffect(() => {
        setSelectedFile(null);
    }, [openModal]);

    const handleSubmit = async (event) => {
        event.preventDefault()
        const formData = new FormData();
        formData.append("file", selectedFile);
        setOpenModal(false)
        dispatch(loading(t('files.in-progress')));
        axiosInstance.post(url, formData).then(res => {
            onSuccess();
            toast.success(t('files.success'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        })
    }

    const handleFileSelect = (event) => {
        setSelectedFile(event.target.files[0])
    }

    return (
        <>
            <Button
                floated='right'
                labelPosition='left'
                icon
                primary
                size='small'
                onClick={() => setOpenModal(true)}>
                <Icon name='download'/> {t('files.import')}
            </Button>
            <Modal size={'tiny'} open={openModal}>
                <Modal.Header>{t('files.import-file')}</Modal.Header>
                <Modal.Content style={{textAlign: 'center'}}>
                    <Segment placeholder>
                        <Button as="label" icon htmlFor="file" type="button" labelPosition={"left"}>
                            <Icon name="file excel outline"/>
                            {t('files.chose')}
                        </Button>
                        <input type="file" id="file" style={{display: "none"}} onChange={handleFileSelect}/>
                        {selectedFile ? <><h4>{selectedFile.name}</h4>
                                <a onClick={() => setSelectedFile(null)}>{t('files.delete')}</a></>
                            : <h4>{t('files.no-file')}</h4>}
                    </Segment>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => setOpenModal(false)}>
                        {t('generic.cancel')}
                    </Button>
                    <Button primary onClick={handleSubmit}>
                        {t('files.import')}
                    </Button>

                </Modal.Actions>
            </Modal>
        </>

    );
}

export default FileImportButton;
