import {LOGIN_ERROR, LOGIN_SUCCESS, LOGOUT, REFRESH_TOKEN} from "./user.types";

const INITIAL_STATE = {
    login: "",
    broker: null,
    firstName: "",
    lastName: "",
    roles: "",
    token: "",
    isLogged: false,
    loginErrorMessage: "",
    tenant: "",
    expirationTokenDate: null
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case LOGIN_ERROR: {
            return {
                ...state,
                login: "",
                loginErrorMessage: action.loginErrorMessage,
                isLogged: false,
                broker: null,
                firstName: "",
                lastName: "",
                roles: "",
                token: "",
                tenant: "",
                expirationTokenDate: null
            }
        }

        case LOGIN_SUCCESS: {
            return {
                ...state,
                isLogged: true,
                login: action.payload.login,
                broker: action.payload.broker,
                token: action.payload.token,
                roles: action.payload.roles,
                firstName: action.payload.firstName,
                lastName: action.payload.lastName,
                tenant: action.tenant,
                loginErrorMessage: "",
                expirationTokenDate: action.payload.expirationDate
            }
        }

        case LOGOUT: {
            return {
                ...state,
                login: "",
                isLogged: false,
                loginErrorMessage: "",
                broker: null,
                firstName: "",
                lastName: "",
                roles: "",
                token: "",
                tenant: "",
                expirationTokenDate: null
            }
        }

        case REFRESH_TOKEN: {
            return {
                ...state,
                token: action.payload.token,
                expirationTokenDate: action.payload.expirationDate
            }
        }

        default:
            return state;
    }
};

export default reducer;