import {Button, Form, Table} from 'semantic-ui-react'
import React, {useEffect, useState} from "react";
import axiosInstance from "../../utils/axiosInstance";
import {NumericFormat} from "react-number-format";
import {useTranslation} from "react-i18next";

function SelectShoptable(props) {

    const {t} = useTranslation();

    const {interventionId, zipCode, onSuccess} = props;
    const [shops, setShops] = useState([]);
    const [zipCodeForm, setZipCodeForm] = useState(zipCode);
    const [zipCodeValid, setZipCodeValid] = useState(false);

    function fetchShops() {
            axiosInstance.get("/shops", {
                params: {
                    zipCode: zipCodeForm,
                    page: 0,
                    size: 5
                }
            }).then(response => {
                if (response.data) {
                    setShops(response.data.content);
                }
            }).catch(function (error) {
                setShops([]);
            });
    }

    useEffect(() => {
        setZipCodeValid(isFrenchZipcode(zipCodeForm))
        fetchShops();
    }, [])

    function updateShop(shop) {
        onSuccess(shop);
    }

    const handleChange = (e, {name, value}) => {
        setZipCodeForm(value);
        setZipCodeValid(isFrenchZipcode(value))
    }

    const handleSubmit = () => {
        fetchShops();
    }

    function isFrenchZipcode(zipCode) {
        var regex = /^\d{5}$/;
        return regex.test(zipCode);
    }


    return (
        <>
            {/*<Menu borderless={true} text style={{margin: 'Opx'}}>*/}
            {/*    <Menu.Menu position='right'>*/}

            <div style={{float: 'right'}}>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group>
                            <Form.Input
                                placeholder={t('generic.postal-code')}
                                name='zipCode'
                                defaultValue={zipCode}
                                onChange={handleChange}
                            />
                            <Form.Button content={t('generic.search')} disabled={!zipCodeValid}/>
                        </Form.Group>
                    </Form>
                </div>
            {/*    </Menu.Menu>*/}
            {/*</Menu>*/}
            <Table striped>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>{t('generic.name')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('generic.address')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('generic.postal-code')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('generic.city')}</Table.HeaderCell>
                        <Table.HeaderCell>Distance</Table.HeaderCell>
                        <Table.HeaderCell>{t('generic.actions')}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {shops && shops.map((shop) => (
                        <Table.Row key={shop.id}>
                            <Table.Cell>{shop.name}</Table.Cell>
                            <Table.Cell>{shop.street}</Table.Cell>
                            <Table.Cell>{shop.zipCode}</Table.Cell>
                            <Table.Cell>{shop.city}</Table.Cell>
                            <Table.Cell><NumericFormat
                                value={shop.distance}
                                displayType={'text'}
                                fixedDecimalScale={true}
                                decimalScale={1}
                                decimalSeparator=','
                                suffix=' km'
                            /></Table.Cell>
                            <Table.Cell>
                                <Button as='a' href={shop.url} target={"_blank"} basic size={"mini"} primary>
                                    {t('generic.website')}
                                </Button>
                                <Button size={"mini"} primary onClick={() => {
                                    updateShop(shop);
                                }}>{t('generic.select')}</Button>
                            </Table.Cell>
                        </Table.Row>
                    ))}
                    {shops.length === 0 && (
                        <Table.Row>
                            <Table.Cell colSpan={5}>  {t('page.intervention.no-shop')}</Table.Cell>
                        </Table.Row>
                    )}
                </Table.Body>
            </Table>
        </>
    );
}

export default SelectShoptable;
