import React, {useState} from "react"
import {Button, Card, Icon, Popup, Table} from 'semantic-ui-react'
import {useNavigate} from "react-router-dom";
import {
    selectProductType,
    updateColor,
    updateCustomer,
    updateGuaranteeType,
    updateProduct
} from "../../reducers/Create/create.actions";
import {useDispatch} from "react-redux";
import colorStatusMap from "../../utils/colorStatus";
import {useTranslation} from "react-i18next";
import axiosInstance from "../../utils/axiosInstance";
import {getAllFleets, getAllProductsByFleetId} from "./FleetService";

function TableFleetProduct(prop) {

    const navigate = useNavigate();
    const [products, setProducts] = useState(prop);
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const menuChange = (path) => {
        navigate(path);
    }

    const deleteProduct = (product) => {
        axiosInstance.delete("/fleets/products/" + product.fleetProductId)
            .then((response) => {
                getAllProductsByFleetId(setProducts, product.fleetId)
            })
            .catch((e) => {
            });

    }

    const createIntervention = (product) => {

        dispatch(updateProduct({
            imei: product.imei,
            color: product.color,
            brand: {
                id: product.brandId,
                name: product.brandName
            },
            model: {
                id: product.modelId,
                name: product.modelName,
            }
        }));
        dispatch(selectProductType(product.productType));
        dispatch(updateColor(product.color));
        dispatch(updateGuaranteeType("COV_BROKER"));
        dispatch(updateCustomer({
            lastName: product.lastName,
            firstName: product.firstName,
            email: product.email,
            phone: product.phoneNumber,
            address: product.address,
            city: product.city,
            zipCode: product.postalCode
        }));
        menuChange("/create/" + product.fleetProductId)
    }

    return <div>
        <Table celled compact definition>
            <Table.Header fullWidth>
                <Table.Row>
                    <Table.HeaderCell/>
                    <Table.HeaderCell>IMEI</Table.HeaderCell>
                    <Table.HeaderCell>{t('generic.brand')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('generic.model')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('generic.phone-number')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('generic.user')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('generic.exernal-id')}</Table.HeaderCell>
                    <Table.HeaderCell>Actions</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {prop.products && prop.products.map(product =>
                    <Table.Row key={product.id} style={{cursor: 'pointer'}}>
                        <Table.Cell onClick={() => menuChange('/fleets/'+product.fleetId+'/products/'+product.fleetProductId+'/update')}> {product.fleetProductId}</Table.Cell>
                        <Table.Cell onClick={() => menuChange('/fleets/'+product.fleetId+'/products/'+product.fleetProductId+'/update')}> {product.imei}</Table.Cell>
                        <Table.Cell onClick={() => menuChange('/fleets/'+product.fleetId+'/products/'+product.fleetProductId+'/update')}> {product.brandName}</Table.Cell>
                        <Table.Cell onClick={() => menuChange('/fleets/'+product.fleetId+'/products/'+product.fleetProductId+'/update')}>
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <Card.Header style={{marginRight: '10px'}}
                                >{product.modelName}</Card.Header>
                                <Popup
                                    content={t('colors.' + product.color)}
                                    on="hover"
                                    trigger={<div
                                        style={{
                                            backgroundImage: product.color !== null ? '' : 'repeating-linear-gradient(to right top, rgb(112, 240, 174), rgb(112, 240, 174) 0.9px, rgb(246, 244, 244) 0.9px, rgb(246, 244, 244) 1.8px, rgb(34, 146, 216) 1.8px, rgb(34, 146, 216) 2.7px)',
                                            backgroundColor: product.color === null ? '' : colorStatusMap.get(product.color).color,
                                            width: '20px',
                                            height: '20px',
                                            borderRadius: '50%',
                                            marginRight: '30px',
                                        }}>
                                    </div>}/>
                            </div>
                        </Table.Cell>
                        <Table.Cell onClick={() => menuChange('/fleets/'+product.fleetId+'/products/'+product.fleetProductId+'/update')}> {product.phoneNumber}</Table.Cell>
                        <Table.Cell onClick={() => menuChange('/fleets/'+product.fleetId+'/products/'+product.fleetProductId+'/update')}> {product.firstName} {product.lastName}</Table.Cell>
                        <Table.Cell onClick={() => menuChange('/fleets/'+product.fleetId+'/products/'+product.fleetProductId+'/update')}> {product.externalId}</Table.Cell>
                        <Table.Cell>
                            <Button.Group basic size='small'>
                                <Popup size='small' content={"Editer le produit"}
                                       trigger={<Button basic icon
                                                        onClick={() => menuChange('/fleets/'+product.fleetId+'/products/'+product.fleetProductId+'/update')}>
                                           <Icon name='edit'/>
                                       </Button>}/>

                                <Popup size='small' content={"Créer une intervention"}
                                       trigger={<Button basic icon
                                                        onClick={() => createIntervention(product)}>
                                           <Icon name='arrow alternate circle right outline' color={"red"}/>
                                       </Button>}/>

                                <Popup size='small' content={t('page.fleet.delete-product')}
                                       trigger={<Button basic icon
                                                        onClick={() =>deleteProduct(product)}>
                                           <Icon color={'red'} name='delete'/>
                                       </Button>}/>
                            </Button.Group>
                        </Table.Cell>
                    </Table.Row>
                )}
            </Table.Body>
        </Table>
    </div>
}

export default TableFleetProduct;