import React, {useEffect, useState} from "react";
import {
    Button,
    Card,
    Checkbox,
    Form,
    Header,
    Message,
    Segment,
    Select
} from 'semantic-ui-react'
import {useNavigate, useParams} from "react-router-dom";
import axiosInstance from "../../utils/axiosInstance";
import {isIMEI} from "../../utils/imei-utils";
import useModelList from "../../hooks/useModelList";
import useBrandList from "../../hooks/useBrandList";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import moment from "moment";
import {useTranslation} from "react-i18next";
import colorStatusMap from "../../utils/colorStatus";
import StandardLayout from "../../layouts/StandardLayout";

function ProductForm(prop) {

    const [fleet, setFleet] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [address, setAddress] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [country, setCountry] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [city, setCity] = useState("");
    const [imei, setImei] = useState("");
    const [serialNumber, setSerialNumber] = useState("");
    const [externalId, setExternalId] = useState("");

    const [purchaseDate, setPurchaseDate] = useState(null);

    const {fleetId} = useParams();
    const {fleetProductId} = useParams();

    const [model, setModel] = useState("");
    const [color, setColor] = useState(null);
    const [brand, setBrand] = useState("");
    const [brandId, setBrandId] = useState("");
    const [modelId, setModelId] = useState("");
    const [enabled, setEnabled] = useState(true);

    const [, modelsOptions] = useModelList(brandId);
    const [activeSearch, setActiveSearch] = useState(false);
    const [, brandsOptions] = useBrandList(activeSearch);

    const [isSameAsBroker, setIsSameAsBroker] = useState(true);
    const [isShowModelMessage, setIsShowModelMessage] = useState(false);

    const {t} = useTranslation();
    let navigate = useNavigate();

    const handleInputChange = (e) => {
        setIsShowModelMessage(false)
        const value = e.target.value;
        const inputValue = value.replace(/\D/g, '').slice(0, 15);
        setImei(inputValue);

        if (isIMEI(inputValue)) {
            axiosInstance.get("/imei/" + value).then(response => {
                if (response.data) {
                    setImei(value);
                    setIsShowModelMessage(true)
                    setBrandId(response.data.brand.id);
                    setModelId(response.data.id);
                    setModel(response.data.name);
                    setBrand(response.data.mobileBrandName);
                    // setIsValid(true)
                } else {
                    // setIsValid(false);
                }
            });
        } else {
            // setIsValid(false);
        }
    };

    useEffect(() => {
        if (prop.product !== undefined && prop.product !== null) {
            setFirstName(prop.product.firstName)
            setLastName(prop.product.lastName)
            setAddress(prop.product.address)
            setPostalCode(prop.product.postalCode)
            setCountry(prop.product.country)
            setEmail(prop.product.email)
            setPhoneNumber(prop.product.phoneNumber)
            setCity(prop.product.city)
            setImei(prop.product.imei)
            setColor(prop.product.color)
            setSerialNumber(prop.product.serialNumber)
            setExternalId(prop.product.externalId)
            setPurchaseDate(prop.product.purchaseDate)
            setModel(prop.product.modelName)
            setBrand(prop.product.brandName)
            setModelId(prop.product.modelId)
            setBrandId(prop.product.brandId)
            setIsShowModelMessage(true)
            setIsSameAsBroker(false)
            // setModelId()
        }
    }, [prop.product]);

    useEffect(() => {
        if (fleetId !== undefined && fleetId !== null) {
            axiosInstance.get("/fleets/" + fleetId)
                .then((response) => {
                    setFleet(response.data);
                })
                .catch((error) => {
                    console.log(error)
                });
        }
    }, []);

    const annuler = () => {
        navigate("/fleets");
    };

    const submit = () => {
        const data = {
            fleetId: fleetId,
            firstName: isSameAsBroker ? fleet.firstName : firstName,
            lastName: isSameAsBroker ? fleet.lastName : lastName,
            address: isSameAsBroker ? fleet.address : address,
            postalCode: isSameAsBroker ? fleet.postalCode : postalCode,
            country: isSameAsBroker ? fleet.country : country,
            email: isSameAsBroker ? fleet.email : email,
            phoneNumber: isSameAsBroker ? fleet.phoneNumber : phoneNumber,
            city: isSameAsBroker ? fleet.city : city,
            imei: imei,
            color: color,
            serialNumber: serialNumber,
            externalId: externalId,
            purchaseDate: purchaseDate,
            modelId: modelId,
            brandId: brandId,
            enabled: enabled
        };
        if (fleetProductId !== undefined && fleetProductId !== "") {
            // setControl(false)
            axiosInstance.put("/fleets/products/" + fleetProductId, data)
                .then(res => {
                    navigate('/fleets/products/' + fleet.fleetId)
                }).catch(e => {
                console.log(e);
            })
        } else {
            // setControl(false)
            axiosInstance.post("/fleets/products", data)
                .then(res => {
                    navigate('/fleets/' + fleet.fleetId + '/products');
                }).catch(e => {
                console.log(e);
            })
        }
    };

    const displayTitle = () => {
        let title;
        if (fleetProductId !== undefined && fleetProductId !== "") {
            title = 'Modifier le/la '
        } else {
            title = 'Créer un/une '
        }
        return title + fleet.productType + ' pour la flotte ' + fleet.name
    }

    return <div>


        <StandardLayout title={displayTitle()} page={
            <>
                <Header attached={"top"} block={true} as='h3'>
                    {t('page.fleet.product-info')}
                </Header>
                <Segment attached>
                    <Message info hidden={!isShowModelMessage}>
                        <Message.Content>
                            <label>Vous avez sélectionné un </label>{brand} {model}
                        </Message.Content>
                    </Message>
                    <Form>
                        <Form.Group widths='equal'>
                            {fleet.productType === 'SMA' ? <>
                                    <Form.Field required={true}>
                                        <label htmlFor="imei">IMEI</label>
                                        <input fluid
                                               id={"imei"}
                                               name="imei"
                                               value={imei}
                                               required={true}
                                               onChange={handleInputChange}
                                        /></Form.Field>
                                </>
                                : <>
                                    <Form.Field required={false}>
                                        <label htmlFor="serialNumber">{t('generic.serial-number')}</label>
                                        <input fluid name="serialNumber"
                                               value={serialNumber}
                                               id={"serialNumber"}
                                               onChange={(e, {value}) => setSerialNumber(value)}
                                        />
                                    </Form.Field>
                                    <Form.Field required={true}>
                                        <label htmlFor="imei">{t('generic.brand')}</label>
                                        <Form.Select
                                            name="brandId"
                                            placeholder={t('page.intervention.select-brand')}
                                            options={brandsOptions}
                                            search
                                            onSearchChange={() => setActiveSearch(true)}
                                            fluid
                                            clearable
                                            onChange={async (e, {name, value}) => {
                                                setBrandId(value.id)
                                            }}
                                        />
                                    </Form.Field>
                                    <Form.Field required={true}>
                                        <label htmlFor="modele">{t('generic.model')}</label>
                                        <Form.Select
                                            name="modele"
                                            placeholder={t('page.intervention.select-model')}
                                            options={modelsOptions}
                                            search
                                            disabled={!brandId}
                                            onSearchChange={() => setActiveSearch(true)}
                                            fluid
                                            clearable
                                            onChange={async (e, {name, value}) => {
                                                setModel(value.id)
                                            }}
                                        />
                                    </Form.Field>

                                </>}

                            <Form.Field>
                                <label htmlFor="externalId">Identifiant externe</label>
                                <input fluid name="externalId"
                                       id={"externalId"}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label htmlFor="color">Couleur</label>
                                {color ?
                                    <Card
                                        style={{
                                            height: '34px',
                                            width: '260px',
                                            marginRight: '3px',
                                            marginBottom: '2px',
                                            marginTop: '6px',
                                            backgroundColor: '#ffffff'
                                        }}
                                        onClick={() => setColor(null)}
                                    >
                                        <Card.Content>
                                            <div style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}>
                                                <div
                                                    style={{
                                                        width: '20px',
                                                        height: '20px',
                                                        marginBottom: '70px',
                                                        marginTop: '-5px',
                                                        backgroundColor: colorStatusMap.get(color).color,
                                                        borderRadius: '50%',
                                                        marginRight: '10px',
                                                        border: '1px solid black', // Ajout de la bordure noire
                                                    }}
                                                ></div>
                                                <Card.Header style={{
                                                    width: '20px',
                                                    height: '20px',
                                                    marginBottom: '70px',
                                                    marginTop: '-5px'
                                                }}
                                                >{t('colors.' + color)}</Card.Header>
                                            </div>
                                        </Card.Content>
                                    </Card>
                                    :
                                    <Form.Field>
                                        <Select
                                            placeholder="Sélectionner une couleur"
                                            className="ui fluid search selection dropdown"
                                            selection>
                                            <Select.Menu>
                                                {Array.from(colorStatusMap.keys()).map((color) => (
                                                    <Select.Item value={color} onClick={() => setColor(color)}>
                                                        <div style={{
                                                            // width: '5px',
                                                            height: '1px',
                                                            marginRight: '3px',
                                                            marginBottom: '1px',
                                                            display: 'flex',
                                                            alignItems: 'center'
                                                        }}>
                                                            <div
                                                                style={{
                                                                    width: '20px',
                                                                    height: '20px',
                                                                    backgroundColor: colorStatusMap.get(color).color,
                                                                    borderRadius: '40%',
                                                                    marginRight: '10px',
                                                                    border: '1px solid black', // Ajout de la bordure noire
                                                                }}
                                                            ></div>
                                                            {t('colors.' + color)}
                                                        </div>
                                                    </Select.Item>
                                                ))}
                                            </Select.Menu>
                                        </Select>
                                    </Form.Field>}

                            </Form.Field>
                            <Form.Field>
                                <label htmlFor="purchaseDate">Date d'achat</label>
                                <SemanticDatepicker onChange={(e, {value}) => setPurchaseDate(value)}
                                                    type="basic"
                                                    value={purchaseDate !== null ? new Date(moment(Date.parse(purchaseDate)).format('MM/DD/YYYY')) : ""}
                                                    id={"purchaseDate"}
                                                    format={'DD/MM/YYYY'}
                                                    locale={"fr-FR"}/>
                            </Form.Field>
                        </Form.Group>
                    </Form>
                </Segment>

                <Header attached={"top"} block={true} as='h3'>Informations sur l'utilisateur du produit</Header>
                <Segment attached>
                    <Message warning>
                        <Message.Content>
                            <Checkbox
                                value={isSameAsBroker}
                                checked={isSameAsBroker}
                                onChange={(e, {label, value}) => setIsSameAsBroker(!isSameAsBroker)}/>
                            <label> Utiliser les données du compte principal du Partenaire, elles seront utilisées pour
                                les interventions.</label>
                        </Message.Content>
                    </Message>

                    <Form>
                        <Form.Group widths='equal'>
                            <Form.Field required={true}>
                                <label htmlFor="firstName">{t('generic.firstname')}</label>
                                <input fluid name="firstName"
                                       id={"firstName"}
                                       readOnly={isSameAsBroker}
                                       disabled={isSameAsBroker}
                                       onChange={(e) => setFirstName(e.target.value)}
                                       value={isSameAsBroker ? fleet.firstName : firstName}/>
                            </Form.Field>
                            <Form.Field required={true}>
                                <label htmlFor="lastName">{t('generic.lastname')}</label>
                                <input fluid name="lastName"
                                       id={"lastName"}
                                       readOnly={isSameAsBroker}
                                       disabled={isSameAsBroker}
                                       onChange={(e) => setLastName(e.target.value)}
                                       value={isSameAsBroker ? fleet.lastName : lastName}/>
                            </Form.Field>

                            <Form.Field required={true}>
                                <label htmlFor="phoneNumber">{t('generic.phone-number')}</label>
                                <input fluid name="phoneNumber"
                                       id={"phoneNumber"}
                                       readOnly={isSameAsBroker}
                                       disabled={isSameAsBroker}
                                       onChange={(e) => setPhoneNumber(e.target.value)}
                                       value={isSameAsBroker ? fleet.phoneNumber : phoneNumber}/>
                            </Form.Field>

                            <Form.Field required={true}>
                                <label htmlFor="email">{t('generic.email')}</label>
                                <input fluid name="email"
                                       id={"email"}
                                       readOnly={isSameAsBroker}
                                       disabled={isSameAsBroker}
                                       onChange={(e) => setEmail(e.target.value)}
                                       value={isSameAsBroker ? fleet.email : email}/>
                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths='equal'>
                            <Form.Field required={true}>
                                <label htmlFor="address">{t('generic.address')}</label>
                                <input fluid name="address"
                                       id={"address"}
                                       readOnly={isSameAsBroker}
                                       disabled={isSameAsBroker}
                                       onChange={(e) => setAddress(e.target.value)}
                                       value={isSameAsBroker ? fleet.address : address}/>
                            </Form.Field>

                            <Form.Field required={true}>
                                <label htmlFor="postalCode">{t('generic.postal-code')}</label>
                                <input fluid name="postalCode"
                                       id={"postalCode"}
                                       readOnly={isSameAsBroker}
                                       disabled={isSameAsBroker}
                                       onChange={(e) => setPostalCode(e.target.value)}
                                       value={isSameAsBroker ? fleet.postalCode : postalCode}/>
                            </Form.Field>

                            <Form.Field required={true}>
                                <label htmlFor="city">{t('generic.city')}</label>
                                <input fluid name="city"
                                       id={"city"}
                                       readOnly={isSameAsBroker}
                                       disabled={isSameAsBroker}
                                       onChange={(e) => setCity(e.target.value)}
                                       value={isSameAsBroker ? fleet.city : city}/>
                            </Form.Field>

                            <Form.Field required={true}>
                                <label htmlFor="country">{t('generic.country')}</label>
                                <input fluid name="country"
                                       id={"country"}
                                       readOnly={isSameAsBroker}
                                       disabled={isSameAsBroker}
                                       onChange={(e) => setCountry(e.target.value)}
                                       value={isSameAsBroker ? fleet.country : country}/>
                            </Form.Field>
                        </Form.Group>

                    </Form>
                </Segment>

                <Button type='' onClick={annuler}>{t('generic.cancel')}</Button>
                <Button primary onClick={submit}>{t('generic.validate')}</Button> </>}
        />
    </div>

}

export default ProductForm;
