export const SET_PRODUCT_TYPE = 'SET_PRODUCT_TYPE';
export const SET_PRODUCT = 'SET_PRODUCT';
export const SET_COLOR = 'SET_COLOR';
export const RESET = 'RESET';
export const UPDATE_REPAIRS = 'UPDATE_REPAIRS';
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';
export const UPDATE_CONDITIONS = 'UPDATE_CONDITIONS';
export const CLEAR_CREATION_WORKFLOW = 'CLEAR_CREATION_WORKFLOW';
export const SET_INTERVENTION_ID = 'SET_INTERVENTION_ID';
export const SET_GUARANTEE_TYPE = 'SET_GUARANTEE_TYPE';

