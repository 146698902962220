import {
    SET_COLOR,
    UPDATE_REPAIRS,
    SET_PRODUCT,
    SET_PRODUCT_TYPE,
    ADD_REPAIR,
    UPDATE_CUSTOMER,
    UPDATE_CONDITIONS, CLEAR_CREATION_WORKFLOW, SET_INTERVENTION_ID, SET_GUARANTEE_TYPE
} from "./create.types";

export function selectProductType(productType) {
    return async function (dispatch) {
        dispatch({type: SET_PRODUCT_TYPE, productType: productType});
    }
}

export function updateProduct(product) {
    return async function (dispatch) {
        dispatch({type: SET_PRODUCT, product: product});
    }
}

export function updateColor(color) {
    return async function (dispatch) {
        dispatch({type: SET_COLOR, color: color});
    }
}

export function updateGuaranteeType(guaranteeType, guaranteeComment, damageDate) {
    return async function (dispatch) {
        dispatch({type: SET_GUARANTEE_TYPE, guaranteeType: guaranteeType, guaranteeComment: guaranteeComment, damageDate: damageDate});
    }
}


export function updateInterventionId(interventionId) {
    return async function (dispatch) {
        dispatch({type: SET_INTERVENTION_ID, interventionId: interventionId});
    }
}

export function updateRepairs(repairs) {
    return async function (dispatch) {
        dispatch({type: UPDATE_REPAIRS, repairs: repairs});
    }
}

export function updateCustomer(customer) {
    return async function (dispatch) {
        dispatch({type: UPDATE_CUSTOMER, customer: customer});
    }
}

export function updateConditions(conditions) {
    return async function (dispatch) {
        dispatch({type: UPDATE_CONDITIONS, conditions: conditions});
    }
}

export function clearCreationWorkflow() {
    return async function (dispatch) {
        dispatch({type: CLEAR_CREATION_WORKFLOW});
    }
}